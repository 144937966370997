import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createRoot } from 'react-dom/client';
import begin from './scene';
import { useCallbackRef } from '@restart/hooks';
import './assets/style/css/swiper-bundle.min.css';
import './assets/style/css/poster.min.css';
import './bootstrap.scss';
import { Card, Form } from 'react-bootstrap';
import Offcanvas from './Offcanvas';
import Modal from './Modal';
import useBreakpoint from '@restart/hooks/useBreakpoint';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import * as serviceWorker from './serviceWorker';
import * as html2image from 'html-to-image';
import QRCode from 'qrcode';
import { flushSync } from 'react-dom';
export const App: React.FC = () => {
  const [show, handleClose] = useState(false);
  const [data, setData] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const screenXs = useBreakpoint('xs');
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [sharepageShow, setSharepageShow] = useState(false);
  const [detailpageShow, setDetailpageShow] = useState(false);
  const shareData = useRef<any>();
  const [scrollRef] = useScrollSave();
  const toPngRef = useRef<HTMLDivElement>(null);
  const [qrcodeSrc, setQrcodeSrc] = useState('');
  const [shareImageUrl, setShareImageUrl] = useState('');
  const offcanvasRef = useRef<any>(null);
  const [detailAttch, detailRef] = useCallbackRef<any>();
  const [detailLoading, setDetailLoading] = useState(false);

  useUpdateEffect(() => {
    if (!detailpageShow) {
      begin.event.emit('hidePic');
    }
  }, [detailpageShow]);
  useEffect(
    function () {
      if (detailAttch) {
        begin.event.emit('picInit');
      }
    },
    [detailAttch]
  );
  useEffect(() => {
    if (!sharepageShow) {
      setShareImageUrl('');
    }
  }, [sharepageShow]);

  const updateDetail = useCallback(() => {
    begin.event.emit('updatePicDetail', shareData.current);
  }, []);
  useEffect(function () {
    begin.ready();
    begin.event.on('onShowSearchView', () => {
      setData(begin.getDataList());
      handleClose(true);
    });
    begin.event.on('detailFinish', (e) => setDetailLoading(false));

    begin.event.on('onDataChange', (e) => setData(e));
    begin.event.on('share', async (obj) => {
      shareData.current = obj;
      console.log(obj.sharelink || obj.privateInviteLink);
      if (obj.sharelink || obj.privateInviteLink) {
        const generateQR = await QRCode.toDataURL(
          obj.sharelink || obj.privateInviteLink,
          {
            width: 192,
          }
        );
        flushSync(() => {
          setQrcodeSrc(generateQR);
          setSharepageShow(true);
        });
      } else {
        flushSync(() => {
          setSharepageShow(true);
        });
      }
      
      flushSync(() => generateImage());
    });
    begin.event.on('detail', async (obj) => {
      shareData.current = obj;
      setDetailpageShow(true);
      setDetailLoading(true);
    });
    return () => {
      begin.event.removeAllListeners();
      begin.dispose();
    };
  }, []);
  const generateImage = useMemo(() => {
    let i = 0;
    return () => {
      i++;
      const m = i;
      toPngRef.current &&
        html2image.toJpeg(toPngRef.current).then((canvas) => {
          if (m === i) {
            toPngRef.current &&
              html2image.toJpeg(toPngRef.current).then((canvas) => {
                toPngRef.current &&
                  html2image.toJpeg(toPngRef.current).then((canvas) => {
                    setShareImageUrl(canvas);
                  });
              });
          }
        });
    };
  }, [toPngRef]);
  console.log(shareData.current);
  const dataset = Object.entries(data).filter(
    (value: any) => !searchValue || value[1]?.title.indexOf(searchValue) > -1
  );
  return (
    <>
      <div id='process_page'>
        <div className='center'>
          <div id='process_num'>0%</div>
          <div className='bar'>
            <div id='process'></div>
          </div>
        </div>
      </div>

      <div id='blur' className='blur'></div>
      <div id='tips'>您已进入私密空间</div>
      <div id='warn'></div>
      <div id='loading'>
        <img className='turn' src='./images/loading.svg' />
      </div>

      <div id='rightMenu'>
        <div id='search' className='btn' onClick={begin.showSearchView}></div>
        <div id='floors'></div>
      </div>

      <div id='container' className='main_bg'>
        <div
          id='door-close'
          className={['ina-btn-close', 'dn'].filter(Boolean).join(' ')}
          onClick={begin.hideprivatescene}
        >
          <div className='i-close'></div>
        </div>
      </div>

      <Modal
        show={detailpageShow}
        onEntered={() => updateDetail()}
        dialogAs={React.Fragment}
        backdropClassName={detailLoading ? 'model_loading' : ''}
      >
        {/* <!--图片详情--> */}
        <div id='pic-detail' className='blur_bg'>
          <div className='pic-area'>
            <div className='pic-show'>
              <div className='flex'>
                <div className='main-left'>
                  <div id='pic_container' ref={detailRef}></div>
                </div>
                {!detailLoading &&
                  shareData.current &&
                  shareData.current.imgObj && (
                    <div className='main-right'>
                      <div id='pic_des' className='pic_des'>
                        <ul className='list-unstyled'>
                          {shareData.current.imgObj.author && (
                            <li>
                              <div className='item'>作者:</div>
                              <div id='author' className='item_data'>
                                {shareData.current.imgObj.author}
                              </div>
                            </li>
                          )}
                          {shareData.current.imgObj.title && (
                            <li>
                              <div className='item'>名称:</div>
                              <div id='title'>
                                {shareData.current.imgObj.title}
                              </div>
                            </li>
                          )}
                          {shareData.current.imgObj.createTime && (
                            <li>
                              <div className='item'>创作时间:</div>
                              <div id='cratetime' className='item_data'>
                                {shareData.current.imgObj.createTime}
                              </div>
                            </li>
                          )}
                          {shareData.current.imgObj.description && (
                            <li>
                              <div className='item'>描述:</div>
                              <div id='des' className='item_data'>
                                {shareData.current.imgObj.description}
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div
            id='detail-close'
            className={['ina-btn-close', detailLoading && 'dn']
              .filter(Boolean)
              .join(' ')}
            onClick={() => setDetailpageShow(false)}
          >
            <div className='i-close'></div>
          </div>
          <div className='btn-actions'>
            {shareData.current && shareData.current.showCollect && (
              <a
                id='collect'
                onClick={() =>
                  begin.event.emit('collectClick', shareData.current.imgObj)
                }
                title='收藏'
              ></a>
            )}
            <a
              id='share'
              onClick={() => begin.event.emit('shareClick', shareData.current)}
              title='分享'
            ></a>
          </div>
        </div>
      </Modal>

      <div id='large-show'>
        <div className='ina-btn-close dn' onClick={begin.closeLargePic}>
          <div className='i-close'></div>
        </div>
        <img id='large-child' />
      </div>

      <div id='sceneSwiper' className='blur_bg'>
        <div className='swiper-exit' onClick={begin.exit}></div>
        <div className='swiper-body'>
          <div id='search_res'>
            包含 ⌜<span id='res_keyword'></span>⌟ 的作品（
            <span id='res_count'></span>）：
          </div>
          <div className='swiper mySwiper'>
            <div className='swiper-wrapper'></div>
          </div>
          <div className='swiper-pagination'></div>
          <div className='swiper-button-next'></div>
          <div className='swiper-button-prev'></div>
        </div>
      </div>

      <div className='search-box'>
        <div className='body'>
          <input
            id='keyword'
            type='text'
            placeholder='请输入关键字'
            onKeyDown={begin.onKeywordKeyDown}
            onFocus={(e) => {
              e.target.placeholder = '';
              return false;
            }}
          />
        </div>
      </div>

      <Offcanvas
        backdropClassName='offcanvas-backdrop'
        ref={offcanvasRef}
        show={show}
        pre='true'
        onHide={handleClose}
        autoFocus
        placement={!screenXs ? 'end' : 'bottom'}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className='me-5' style={{ flex: 1 }}>
            <Form.Control
              value={searchValue}
              size='lg'
              type='search'
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder='请输入关键字'
            />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body ref={scrollRef}>
          <div id='search_res' className='mb-4'>
            {!searchValue && '全部'}
            {searchValue && (
              <>
                包含 ⌜<span id='res_keyword'>{searchValue}</span>⌟ 的
              </>
            )}
            作品（<span id='res_count'>{dataset.length}</span>）：
          </div>
          <div className='row row-cols-2  g-4'>
            {dataset.map((value: any) => {
              const [key, item] = value;
              return (
                <div
                  key={key}
                  onClick={() => {
                    handleClose(false);
                    begin.event.emit('onSearchItemClick', key);
                  }}
                  className='col'
                >
                  <Card>
                    <Card.Img variant='top' src={item.image} />
                    <Card.Body>
                      <Card.Text>{item.title}</Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <div id='guide-area' className='dn'>
        <div id='guide-status'>
          <span className='guide start on'>打开自动导览模式</span>
          <span className='guide exit'>退出导览</span>
        </div>

        <div id='guide_line'>
          <ul className='list-unstyled'></ul>
        </div>
      </div>

      <div id='guide-pic'>
        <div className='ina-btn-close' onClick={begin.closeGuidePic}>
          <div className='i-close'></div>
        </div>
        <img id='guide-pic-child' />
      </div>

      <div id='lyc_area'>
        <span id='volumeNode' className='volumeNode on'></span>
        <span id='lyc'></span>
      </div>
      <Modal
        show={sharepageShow}
        dialogAs={React.Fragment}
        backdropClassName='model_loading'
      >
        <div
          id='share-close'
          className='ina-btn-close'
          onClick={(e) => setSharepageShow(false)}
        >
          <div className='i-close'></div>
        </div>
        {shareData.current && sharepageShow && (
          <div style={{ position: 'absolute', left: '-999999px' }}>
            <div className='poster-container' ref={toPngRef}>
              <div className='flex nr'>
                <div className='top'>
                  <div className='author'>
                    {shareData.current.imgObj.author}
                  </div>
                  <div className='subject'>
                    {shareData.current.imgObj.title}
                  </div>
                  <div className='date'>
                    {shareData.current.imgObj.createTime}
                  </div>
                </div>

                <div className='pic'>
                  <img
                    className='sharepic'
                    alt=''
                    onError={() => generateImage()}
                    onLoad={() => {
                      generateImage();
                    }}
                    src={shareData.current.imgurl}
                  />
                </div>
                <div className='bottom'>
                  <div className='about'>
                    <div className='left'>
                      <div className='title'>
                        {shareData.current.imgObj.exhibitionName}
                      </div>
                      {shareData.current.imgObj.exhibitionStartTime && (
                        <div className='time'>
                          {shareData.current.imgObj.exhibitionStartTime} 至{' '}
                          {shareData.current.imgObj.exhibitionEndTime}
                        </div>
                      )}
                      <div className='logo'>
                        <img alt='' src='./images/i-exlogo.png' />
                        <img alt='' src='./images/i-logo.png' />
                      </div>
                      {shareData.current.iscolor && (
                        <div className='project'></div>
                      )}
                      {!shareData.current.iscolor && (
                        <div>云图里美术馆 · 3S VIP 展厅</div>
                      )}
                    </div>
                    {shareData.current.iscolor && (
                      <div id='qrcode'>
                        <div id='qrcodeDiv' className='qrcode'>
                          <img alt='' src={qrcodeSrc}></img>
                        </div>
                      </div>
                    )}
                    {!shareData.current.iscolor && (
                      <div id='qrcode'>
                        <img id='qrcodelogo' alt='' src='./images/i-door.png' />
                        {qrcodeSrc && (
                          <>
                            <div id='qrcodeDiv' className='qrcode'>
                              <img alt='' src={qrcodeSrc}></img>
                            </div>
                            <div className='yaoting'>限时特邀</div>
                          </>
                        )}
                      </div>
                    )}
                  </div>

                  {shareData.current.iscolor &&
                    shareData.current.imgObj.colorCode && (
                      <div className='code'>
                        <div id='kcis'>
                          KCIS -{' '}
                          <span id='kicscode'>
                            {shareData.current.imgObj.colorCode.code}
                          </span>
                        </div>
                        <div className='color'>
                          <div className='headercolor'>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                          <div id='colorlist'>
                            {shareData.current.imgObj.colorCode &&
                              shareData.current.imgObj.colorCode.colorList.map(
                                (ele, index) => {
                                  return index ===
                                    shareData.current.imgObj.colorCode.colorList
                                      .length -
                                      1 ? (
                                    <div key={index}>
                                      <div className='black'></div>
                                      <div
                                        style={{
                                          background: `rgb(${ele.R},${ele.G} ,${ele.B})`,
                                        }}
                                      ></div>
                                    </div>
                                  ) : (
                                    <div
                                      key={index}
                                      style={{
                                        background: `rgb(${ele.R},${ele.G} ,${ele.B})`,
                                      }}
                                    ></div>
                                  );
                                }
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>

              <img
                className='hbb'
                onLoad={() => generateImage()}
                alt=''
                src='./images/hbbian.png'
              />
              <div className='shadowtop'></div>
              <div className='shadowleft'></div>
            </div>
          </div>
        )}

        <div className='toimg'>
          <img
            id='posterImg'
            alt=''
            src={shareImageUrl}
            className='img-responsive'
            style={{ display: 'inline-block' }}
          />
        </div>
      </Modal>

      <div id='logincenter' className='dn'>
        <iframe
          id='login'
          width='100%'
          height='100%'
          src=''
          style={{ order: 0 }}
        ></iframe>
      </div>

      <div id='avator' className='dn'>
        <a id='usercenter'>
          <img id='nofaceimg' className='img_avator' src='/images/avatar.png' />
        </a>
      </div>
    </>
  );
};

const root_em = createRoot(document.getElementById('root')!);
root_em.render(<App />);
serviceWorker.register();

function useScrollSave() {
  const [scroll, scrollRef] = useCallbackRef<HTMLDivElement>();
  const scrollXY = useRef({ x: 0, y: 0, height: 0 });
  useEffect(
    function () {
      function onscroll(e) {
        scrollXY.current.x = e.target.scrollLeft;
        scrollXY.current.y = e.target.scrollTop;
      }
      if (scroll) {
        scroll.addEventListener('scroll', onscroll);
      }

      let timer = setTimeout(() => {
        scroll && scroll.scrollTo(scrollXY.current.x, scrollXY.current.y);
      }, 500);

      return () => {
        clearTimeout(timer);
        scroll?.removeEventListener('scroll', onscroll);
      };
    },
    [scroll]
  );
  return [scrollRef];
}
