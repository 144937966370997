/* eslint-disable */
import * as THREE from "three";
import $ from "jquery";
import TWEEN from "@tweenjs/tween.js";
import { OrbitControls } from "./three/controls/OrbitControls";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { CSS3DRenderer, CSS3DObject } from "three/examples/jsm/renderers/CSS3DRenderer";
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils";
import setting from "./setting";
import TextureUtils from "./TextureUtils";
import EnvironmentScene, { EnvironmentSceneAlt } from "./EnvironmentScene"
import { DoubleSide, TextureLoader } from "three";
import EventEmitter from "events";
import Map from "./map";
let event = new EventEmitter();
let scene, renderer, camera, controls, angle;
let container;
let sid;

let envMap;
let textureUtils;
var ismobile = false, invitecode = "", position, id = 1;
var labelRenderer, clock = new THREE.Clock(), player, gltfloader, plyloader;
var objlist = [], artList = {}, posterList = {}, videoList = {}, introList = {}, guideList = {};
var cameradistance = 80, cameraheight = 30
var isCrashed = true, circle, sky = null
var moveObj = new THREE.Group();
var raycaster = new THREE.Raycaster();
var mouse = new THREE.Vector2();
var msg = new THREE.Group();
var currentfloor, privateDoor, door = null, privateenter, banprivate, layerlight, layer, layergroup, doorlogo = [];
var manager = new THREE.LoadingManager();
var picloader = new THREE.TextureLoader();
var mwtexture = new THREE.TextureLoader().load("/images/mwl.jpg");
var wallma = new THREE.MeshBasicMaterial({ opacity: 0, transparent: true, color: 0xffffff, depthWrite: false });

var picboxma = new THREE.MeshBasicMaterial({ map: mwtexture });

var textureLoader = new THREE.TextureLoader();
var loader = new FBXLoader(manager);
var currentBaseAction = null, mixer = null, walk, stand, left, right, speak, mixers = [];
var s_l = 2.5, s_m = 1.428, s_s = 0.924;
var s_m_r = 0.6, s_s_r = 0.4;
var picrender = null;
const baseActions = {
    0: { weight: 1, name: "stand" },
    1: { weight: 0, name: "left" },
    2: { weight: 0, name: "right" },
    3: { weight: 0, name: "walk" },
    4: { weight: 0, name: "speak" },
};
var steplength = 16.0;

var grayma = new THREE.MeshBasicMaterial({ color: 0xcccccc });
var postergeo = new THREE.BoxBufferGeometry(90, 130, 5);
var video, right_video, turnvideo, space, spacethumb;
var isFromPrivate = GetQueryString("FromPrivate")
var isInvite = false, isPrivateSpace = false, isOutFromPrivateSpace = false, dummydoor;
var privateScene = null;




function genturnvideo() {
    var url = ismobile ? "/video/turnM.mp4" : "/video/PC.mp4";
    $("body").append('<video muted  cross-origin id="turn_video" loop webkit-playsinline="true" playsinline="true" x5-video-player-type="h5" x5-video-player-fullscreen="true" x5-video-orientation="portraint" x-webkit-airplay="allow" style="display:none">'
        + '<source src="' + url + '" type="video/mp4">'
        + '</source></video>')
    turnvideo = document.getElementById("turn_video");
}

async function startinit() {
    await initbasicScene();
    //initPicScene();
    initMainscene();
}

function initfloorsButton() {
    var floordiv = document.getElementById("floors");
    //floordiv.style.display = "block";

    for (var i in setting.floors) {
        var floorbtn = document.createElement("div");
        var curfloor = setting.floors[i];

        floorbtn.setAttribute("id", "btn_floor_" + curfloor.floorId)

        floorbtn.className = "floor btn";
        floorbtn.floorId = curfloor.floorId;
        floordiv.appendChild(floorbtn);

        floorbtn.onclick = function () {

            if (this.floorId == currentfloor.floorId) return;
            if ($("#sceneSwiper").hasClass("on")) return;

            exitSearchView();

            executeCrossFade(currentBaseAction, stand, 0.1)
            TWEEN.removeAll();
            updateFloor(this.floorId)

            var pos = currentfloor.startPos;
            circle.position.y = pos.y + 12;
            new TWEEN.Tween({ y: moveObj.position.y })
                .to({ y: pos.y }, 500)
                .onStart(function () {
                    moveObj.visible = false;
                    moveObj.position.x = pos.x;
                    moveObj.position.z = pos.z;
                    controls.target.set(moveObj.position.x, moveObj.position.y + cameraheight, moveObj.position.z);
                    camera.position.set(moveObj.position.x, moveObj.position.y + cameraheight, moveObj.position.z + cameradistance);
                })
                .onUpdate(function (res) {
                    moveObj.position.y = res.y
                    controls.target.y = res.y + cameraheight;
                    camera.position.y = res.y + cameraheight;
                    camera.lookAt(controls.target)
                })
                .onComplete(function () {
                    moveObj.visible = true;
                })
                .start()
        }
    }

    $("#btn_floor_G").addClass("current")
    currentfloor = currentfloor = setting.floors["floor_G"];
}

function updateFloor(floorId) {
    if (floorId == currentfloor.floorId) return;

    $("#btn_floor_" + floorId).addClass("current")
    $("#btn_floor_" + floorId).siblings().removeClass("current");
    refreshUI("floor_" + (floorId == "G" ? 1 : floorId));
    currentfloor = currentfloor = setting.floors["floor_" + floorId]
}

function loadScene(curscene) {
    var scenetype = curscene.scenetype.toLowerCase();
    if (curscene.enable) {
        //console.log("begin load " + curscene.name)
        switch (scenetype) {
            case "fbx":
                var rootPath = curscene.root;
                var url = curscene.url || (rootPath + "/" + curscene.name);
                loader.load(url, function (object) {

                    object.scale.set(curscene.scale, curscene.scale, curscene.scale);
                    object.position.set(curscene.pos.x, curscene.pos.y, curscene.pos.z)
                    msg.add(object);

                    if (curscene.type == "main") {
                        var xkp = msg.getObjectByName("XKP")
                        if (xkp != null) object.remove(xkp)
                        var XiaoDao = msg.getObjectByName("XiaoDao")
                        if (XiaoDao != null) {
                            object.remove(XiaoDao)
                        }

                        var startnode = object;
                        if (curscene.crashnode != undefined && curscene.crashnode != "") {
                            startnode = scene.getObjectByName(curscene.crashnode)
                        }

                        startnode.traverse(function (obj) {
                            initInfo(obj, startnode);
                        });

                        var floor2Weilan = new THREE.Mesh(new THREE.PlaneGeometry(400, 80), wallma);
                        floor2Weilan.rotation.y = Math.PI
                        floor2Weilan.position.set(0, 520, -2000);
                        msg.add(floor2Weilan);
                        objlist.push(floor2Weilan)

                        var leftwall = new THREE.Mesh(new THREE.BoxBufferGeometry(400, 80, 2300), wallma)
                        leftwall.position.set(-3030, 0, -840)
                        var rightwall = leftwall.clone();
                        rightwall.position.set(2950, 0, -840)
                        var topleft = leftwall.clone();
                        topleft.position.set(-870, 0, -4050)
                        var topright = leftwall.clone();
                        topright.position.set(770, 0, -4050)
                        var middle = new THREE.Mesh(new THREE.BoxBufferGeometry(400, 80, 2400), wallma)
                        middle.position.set(0, 0, 1000)

                        msg.add(leftwall, rightwall, topleft, topright, middle)
                        objlist.push(leftwall, rightwall, topleft, topright, middle)

                        //floor2 
                        var r21 = new THREE.Mesh(new THREE.BoxBufferGeometry(420, 80, 2700), wallma);
                        r21.position.set(3786, 530, -2350)
                        var r22 = r21.clone();
                        r22.position.set(3786, 530, 900);
                        var left21 = r21.clone();
                        left21.position.set(-3786, 530, 900);
                        var left22 = r21.clone();
                        left22.position.set(-3786, 530, -2350);

                        var m1 = new THREE.Mesh(new THREE.BoxBufferGeometry(3700, 80, 420), wallma);
                        m1.position.set(-2100, 530, -2750);
                        var m2 = m1.clone();
                        m2.position.set(2100, 530, -2750);

                        msg.add(r21, r22, left21, left22, m1, m2)
                        objlist.push(r21, r22, left21, left22, m1, m2)

                        if (curscene.picnode != undefined && curscene.picnode != "") {
                            var picNode = scene.getObjectByName(curscene.picnode)
                            console.log(scene, picNode)
                            if (picNode) {
                                picNode.traverse(function (obj) {
                                    initPicInfo(obj);
                                });
                            }
                        }

                        drawExtraObj();
                    } else if (curscene.type == "door") {
                        privateDoor = object;


                        //  var ma = new THREE.MeshBasicMaterial({ opacity: 0, transparent: true, color: 0xffffff, depthWrite: false })
                        dummydoor = new THREE.Mesh(
                            new THREE.BoxBufferGeometry(200, 440, 180),
                            wallma
                        );
                        dummydoor.position.copy(object.position)
                        dummydoor.position.x += 50;
                        msg.add(dummydoor)
                        dummydoor.name = "dummydoor"

                        objlist.push(dummydoor)

                        object.traverse(function (obj) {
                            if (obj instanceof THREE.Mesh) {
                                // console.log(obj.name);
                                if (obj.name == "Door_3" || obj.name == "Door_S" || obj.name.startsWith("polySurface129900")) {
                                    doorlogo.push(obj);
                                } else if (obj.name == "polySurface1299") {
                                    obj.name = "door"
                                    door = door = obj;
                                    door.isOpen = false;
                                    //objlist.push(door)
                                    layergroup = layergroup = new THREE.Group();
                                    layergroup.position.x = 100
                                    layergroup.position.y = 200
                                    layergroup.position.z = -6
                                    layergroup.visible = true;

                                    layer = new THREE.Mesh(
                                        new THREE.PlaneGeometry(190, 320),
                                        new THREE.MeshLambertMaterial({
                                            map: textureLoader.load("/images/scenelaser.png"),
                                            transparent: true
                                        })
                                    )
                                    layer.position.z = 0
                                    layergroup.add(layer)
                                    layerlight = new THREE.Mesh(
                                        new THREE.PlaneGeometry(190, 10),
                                        new THREE.MeshLambertMaterial({
                                            map: textureLoader.load("/images/layerlight.png"),
                                            transparent: true,
                                            // alphaTest: 0,
                                            side: THREE.DoubleSide,
                                            opacity: 0.4
                                        })
                                    )

                                    // layerlight.position.x = 0
                                    layerlight.position.y = -150
                                    layerlight.position.z = 0
                                    layergroup.add(layerlight)

                                    privateenter = privateenter = new THREE.Mesh(
                                        new THREE.PlaneGeometry(170, 160)
                                        , new THREE.MeshBasicMaterial({
                                            map: textureLoader.load("/images/enterprivate.png"),
                                            // side: THREE.DoubleSide,
                                            alphaTest: 0.1,
                                            transparent: true
                                        })
                                    );
                                    privateenter.visible = false;
                                    privateenter.position.x = 100
                                    privateenter.position.y = 230
                                    privateenter.position.z = 0

                                    banprivate = banprivate = privateenter.clone();
                                    banprivate.material = new THREE.MeshBasicMaterial({
                                        map: textureLoader.load("/images/banprivate3.png"),
                                        transparent: true,
                                        opacity: 1,
                                        alphaTest: 0.1
                                    })

                                    door.add(privateenter);
                                    door.add(banprivate);
                                    door.add(layergroup)
                                } else {
                                    // crashobjlist.push(obj)
                                }
                            }
                        });

                        space = new THREE.Mesh(
                            new THREE.PlaneGeometry(200, 360),
                            new THREE.MeshBasicMaterial({
                                map: textureLoader.load("/images/tvideothmb.jpg"),
                                side: THREE.DoubleSide
                            })
                        )

                        space.position.x = 110
                        space.position.y = 215
                        space.position.z = -20
                        space.name = "space";

                        // objlist.push(space)
                        object.add(space)
                    }
                });
                break;

        }
    }
}

function initInfo(node, parent) {
    if (node instanceof THREE.Mesh) {

        if (node.geometry && !node.geometry.index) {
            node.geometry = BufferGeometryUtils.mergeVertices(node.geometry.clone());

        }
        if (setting.laddersname.name.toLowerCase() == node.name.toLowerCase()) {
            var dummyladder = node.clone();
            dummyladder.material = wallma;
            scene.add(dummyladder);
            dummyladder.name = "dummyladder"
            dummyladder.position.y -= 33;
            objlist.push(dummyladder);
        } else if (setting.laddersname.dummyname.toLowerCase() == node.name.toLowerCase()) {
            node.position.y -= 15;
        }

        if (node.name.toLowerCase().indexOf('neiqiang') > -1) {
            var dummyWall = node.clone();
            if (node.name.toLocaleLowerCase().indexOf("f3") > -1) {
                dummyWall.scale.set(0.928, 1, 0.928);
            }
            else {
                dummyWall.scale.set(0.956, 1, 0.962);
            }

            dummyWall.material = wallma;
            scene.add(dummyWall);
            objlist.push(dummyWall)
        }
        if (setting.floornames.indexOf(node.name) > -1) {
            var ma = node.material;
            ma.emissive.set(0x303030);
            ma.needsUpdate = true;
        }


        objlist.push(node);
    }

    for (var i = 0; i < node.children.length; i++) {
        if (node.children[i] && node.children[i].length > 0) initInfo(node.children[i]);
    }
}

var skipnodelist = ["P_F1_1", "P_F1_2", "P_F1_3", "P_F2_141", "P_F1_110", "P_F1_111", "P_F1_112", "P_F1_113", "P_F1_114", "P_F1_135", "P_F1_136", "P_F1_137", "P_F1_138", "P_F1_139", "P_F2_173", "P_F2_190", "P_F2_141"]
var checkbox = new THREE.Box3();
var floor2Num = 0, floor3Num = 0, floor1Num = 0;


function setMeshPictureSize(floor, imgObj) {
    s_l = (floor == "2F") ? 1.9 : 2.5
    var w_s = s_l;
    if (imgObj.type == "#120" || imgObj.type == "2280x2280")
        w_s = s_l;
    else if (imgObj.type == "#100" || imgObj.type == "1140x1140")
        w_s = s_l * s_m_r;
    else if (imgObj.type == "#15" || imgObj.type == "750x750") {
        w_s = s_l * s_s_r
    }

}

function initPicInfo(node) {
    if (node instanceof THREE.Mesh) {

        if (node.name.startsWith(setting.picture_start_name)) {
            var pos = new THREE.Vector3();
            pos.copy(node.position);
            var rota = new THREE.Vector3();
            rota.copy(node.rotation);
            var tmplist = node.name.split('_');
            var floor = tmplist[1];
            var num = Number(tmplist[2])
            var room = "";
            var posterMaxh = 380;
            //2楼海报
            if (node.name == "P_F2_190") {
                //2C
                if (posterList.door2c != undefined) {
                    // var c2_texture = picloader.load(posterList.door2c.image);
                    // var c2_poster = new THREE.Mesh(postergeo.clone(), [grayma, grayma, grayma, grayma, new THREE.MeshLambertMaterial({ map: c2_texture }), grayma]);
                    // c2_poster.position.copy(node.position)
                    // //c2_poster.translateZ(-250)
                    // c2_poster.name = "door2c"
                    // c2_poster.rotation.copy(node.rotation)
                    // c2_poster.floorId = "2"
                    // scene.add(c2_poster)
                    posterMaxh = 350;
                    pos.z += -100;
                    pos.y += 25;
                    drawIntroPoster(
                        posterList.door2c.image,
                        posterList.door2c.imageAttr?.orgWidth,
                        posterList.door2c.imageAttr?.orgHeight,
                        pos,
                        rota.x, rota.y, rota.z, "door2c", floor, posterMaxh
                    )
                    console.log("C", pos)
                }

            } else if (node.name == "P_F2_173") {
                //2A
                if (posterList.door2a != undefined) {
                    // var a2_texture = picloader.load(posterList.door2a.image);
                    // var a2_poster = new THREE.Mesh(postergeo.clone(), [grayma, grayma, grayma, grayma, new THREE.MeshLambertMaterial({ map: a2_texture }), grayma]);
                    // a2_poster.position.copy(node.position)
                    // //a2_poster.translateZ(70)
                    // a2_poster.name = "door2a"
                    // a2_poster.rotation.copy(node.rotation)
                    // a2_poster.floorId = "2"
                    // scene.add(a2_poster)
                    posterMaxh = 350;
                    pos.x += -100;
                    pos.y += 25;
                    drawIntroPoster(
                        posterList.door2a.image,
                        posterList.door2a.imageAttr?.orgWidth,
                        posterList.door2a.imageAttr?.orgHeight,
                        pos,
                        rota.x, rota.y, rota.z, "door2a", floor, posterMaxh
                    )
                    console.log("A", pos)
                }

            } else if (node.name == "P_F2_141") {
                //2b
                if (posterList.door2b != undefined) {
                    // var b2_texture = picloader.load(posterList.door2b.image);
                    // var b2_poster = new THREE.Mesh(postergeo.clone(), [grayma, grayma, grayma, grayma, new THREE.MeshLambertMaterial({ map: b2_texture }), grayma]);
                    // b2_poster.position.copy(node.position)
                    // //b2_poster.translateX(-300)
                    // b2_poster.name = "door2b"
                    // b2_poster.rotation.copy(node.rotation)
                    // b2_poster.floorId = "2"
                    // scene.add(b2_poster)
                    posterMaxh = 350;
                    pos.z += 50;
                    pos.y += 25;
                    drawIntroPoster(
                        posterList.door2b.image,
                        posterList.door2b.imageAttr?.orgWidth,
                        posterList.door2b.imageAttr?.orgHeight,
                        pos,
                        rota.x, rota.y, rota.z, "door2b", floor, posterMaxh
                    )
                    console.log("B", pos)
                }
            }

            if (skipnodelist.indexOf(node.name) > -1) {
                freeNode(node);
                return
            }
            //P_F1_114  N_G_1A_1




            if (floor == "F1") {
                floor = "G";

                // //模型标少了一个48号数据
                if (num >= 49) num--;

                if (num == 2 || num == 36 || num == 63) {



                    if (num == 2) {
                        if (introList.intro1a != undefined)
                            drawIntroPoster(
                                introList.intro1a.image,
                                introList.intro1a.imageAttr?.orgWidth,
                                introList.intro1a.imageAttr?.orgHeight,
                                pos,
                                rota.x, rota.y, rota.z, "intro1a", floor, posterMaxh
                            )
                    }
                    else if (num == 36) {
                        if (introList.intro1b != undefined)
                            drawIntroPoster(
                                introList.intro1b.image,
                                introList.intro1b.imageAttr?.orgWidth,
                                introList.intro1b.imageAttr?.orgHeight,
                                pos,
                                rota.x, rota.y, rota.z, "intro1b", floor, posterMaxh
                            )
                    }
                    else if (num == 63) {
                        if (introList.intro1c != undefined)
                            drawIntroPoster(
                                introList.intro1c.image,
                                introList.intro1c.imageAttr?.orgWidth,
                                introList.intro1c.imageAttr?.orgHeight,
                                pos,
                                rota.x, rota.y, rota.z, "intro1c", floor, posterMaxh
                            )
                    }
                    freeNode(node);

                    return;
                } else {

                    if ((num >= 1 && num <= 3) || (num <= 37 && num >= 35) || (num <= 64 && num >= 62)) {
                        freeNode(node)
                        return;
                    }
                    if (num >= 114 && num <= 133) {
                        room = "1B"
                        //from 27+
                        num = num - 87

                    } else if (num >= 139) {
                        room = "1A"
                        //31至35
                        num = num - 107
                    } else if (num > 66 && num <= 109) {
                        num = num - 66
                        room = "1C"
                    } else if (num > 37) {
                        room = "1B"
                        if (num >= 65)
                            num = num - 40
                        else
                            num = num - 37

                    } else if (num <= 34) {
                        room = "1A"
                        num -= 3

                    }
                }


            } else if (floor == "F2") {

                const name = Map['2F'][num];
                if (!name) {
                    freeNode(node);
                    return
                }
                const names = name.split("_");
                floor = names[1];
                num = names[3]
                room = names[2]

            } else if (floor == "F3") {

                floor = "3F"
                //floor3Num++;

                if (num == 70) {
                    var dir = new THREE.Vector3();
                    node.getWorldDirection(dir)
                    node.position.add(dir.multiplyScalar(5))
                }
                // num = floor3Num;
                room = "OUT"


            }
            var artname = "N_" + floor + "_" + room + "_" + num
            node.name = artname;

            var imgObj = artList[artname]

            if (imgObj && imgObj != undefined && imgObj != null && imgObj != "null") {
                var text = "";
                if (imgObj.author != null && imgObj.author != "")
                    text = makeTips(imgObj.title + "\n ⌜" + imgObj.author + "⌟ ");
                else
                    text = makeTips(imgObj.title);

                var box = checkbox.setFromObject(text);
                var size = new THREE.Vector3()
                box.getSize(size);
                var max_w = size.x;
                var text_r = 1.0;


                text.rotation.copy(node.rotation)
                text.position.set(node.position.x, node.position.y - text.scale.y / 2 - 135, node.position.z + 2)

                msg.add(text);
                // return;

                var image = imgObj.image;
                var imgarr = imgObj.imageAttr;
                if (image != null) {

                    s_l = (floor == "2F") ? 1.9 : 2.5
                    var w_s = s_l;
                    if (imgObj.type == "#120" || imgObj.type == "2280x2280")
                        w_s = s_l;
                    else if (imgObj.type == "#100" || imgObj.type == "1140x1140")
                        w_s = s_l * s_m_r;
                    else if (imgObj.type == "#15" || imgObj.type == "750x750") {
                        w_s = s_l * s_s_r
                    }



                    node.material = picboxma;
                    //node.name = artname;
                    node.floorId = floor.substring(0, 1);

                    //显示画
                    var ma = new THREE.MeshBasicMaterial({
                        color: 0xffffff,
                        blending: THREE.NoBlending,
                        map: picloader.load(image.match(/\?.+/) ? image : image + "1140x1140", function (texture) {

                            var h = texture.image.naturalHeight
                            var w = texture.image.naturalWidth

                            if (w == h) {
                                node.scale.x = w_s;
                                node.scale.y = w_s;
                            } else if (w > h) {
                                node.scale.x = w_s;
                                node.scale.y = w_s * h / w
                            } else {
                                node.scale.x = w_s * w / h;
                                node.scale.y = w_s;
                            }
                            var pic = new THREE.Mesh(new THREE.PlaneGeometry((1 - 15 / (node.scale.x * 100)) * 100, (1 - 15 / (node.scale.y * 100)) * 100), ma);
                            node.add(pic)
                            pic.name = node.name;
                            pic.floorId = node.floorId;
                            pic.position.z += 52
                        }),

                        transparent: false,
                    })
                    ma.map.magFilter = THREE.LinearFilter
                    ma.map.minFilter = THREE.LinearMipMapLinearFilter

                    objlist.push(node)

                } else {
                    //free the null data
                    delete artList[artname];
                }
            } else {
                freeNode(node)
            }
        }
    }

    for (var i = 0; i < node.children.length; i++) {
        if (node.children[i] && node.children[i].length > 0) initPicInfo(node.children[i]);
    }
}

function addCrashwall() {
    var wall = new THREE.Mesh(new THREE.PlaneGeometry(10000, 100), wallma)

    wall.rotateY(Math.PI)
    wall.position.set(0, 40, 2000)
    wall.name = "frontwall"

    var wall2 = new THREE.Mesh(new THREE.PlaneGeometry(2000, 100), wallma)
    wall2.position.set(-4600, 40, 1850)
    wall2.rotateY(Math.PI * 0.91)

    var wall3 = wall2.clone();
    wall3.position.set(4550, 40, 1850)
    wall3.rotateY(Math.PI * 0.15)

    var floor2_wall = wall.clone();
    floor2_wall.position.set(0, 540, 2000)

    wall2.name = "dummywall"
    wall3.name = "dummywall"
    floor2_wall.name = "dummywall"

    objlist.push(wall, wall2, wall3, floor2_wall);
    msg.add(wall, wall2, wall3, floor2_wall)
}

function initMainscene() {
    initPicData(function (result) {
        scene = initScene();
        scene.add(msg);
        for (var i = 0; i < setting.scene.length; i++) {
            loadScene(setting.scene[i])
        }
        initplayer();
        addCrashwall();
        //console.log(moveObj)
        scene.add(publicModelGroup);
        let promises = [];
        for (var i = 0; i < setting.customModel.length; i++)
            setting.customModel[i].isPublic && promises.push(initCustomModels(setting.customModel[i]));

        Promise.all(promises).then((gltfs) => {
            setCustonModelGroupAnimation(gltfs);

        })

        if (videoList.video1 != undefined)
            getVideoHtmlByDivName("scene_video", videoList.video1.file);
        if (videoList.video2 != undefined)
            getVideoHtmlByDivName("scene_video_right", videoList.video2.file);
        genSceneVideoGeometry();

    });
}
function setCustonModelGroupAnimation(gltfs) {
    gltfs.forEach(gltf => {
        const animations = gltf.animations;
        if (animations.length) {
            let mixer = new THREE.AnimationMixer(gltf.scene);
            let clip = animations[0];
            mixers.push(mixer)
            const action = mixer.clipAction(clip);

            action.play()
        }
    })

}
function initScene() {
    var curscene = new THREE.Scene();
    var ambientLight = new THREE.AmbientLight("rgb(255, 255, 255)", 0.85);

    curscene.add(ambientLight);
    curscene.add(moveObj)
    if (position) {
        moveObj.visible = true;
    }

    if (circle == null) {
        var circleMa = new THREE.MeshBasicMaterial({ side: THREE.DoubleSide, depthWrite: false, depthTest: false, transparent: true, map: textureLoader.load("/images/point.png") });
        circle = circle = new THREE.Mesh(new THREE.PlaneGeometry(86, 86), circleMa);
        circle.position.y = 3;
        circle.rotateX(-Math.PI / 2);
        circle.name = "circle"
        circle.visible = false;
    }
    curscene.add(circle)
    if (!privateScene) {
        // var spotLight = new THREE.SpotLight(0xffffff)
        // spotLight.position.set(-1333, 3333, -2666);
        // spotLight.intensity = 0.8;
        // spotLight.penumbra = 0.011;
        // spotLight.decay = 1;
        // spotLight.distance = 12000;
        // spotLight.angle = 0.28;
        // curscene.add(spotLight);
        // console.log(spotLight)
    }
    return curscene;
}

function initevent() {
    window.addEventListener('resize', onWindowResize, false);
    container.addEventListener('mousedown', onMouseDown, false);
    document.addEventListener('mouseup', onMouseUp, false);
    // if (("ontouchstart" in window) || navigator.maxTouchPoints) { 
    //     container.addEventListener('touchstart', onDocumentTouchStart, false);
    //     container.addEventListener('touchend', onDocumentTouchEnd, false);
    // } else {
    //     container.addEventListener('click', onMouseClick, false);
    //     container.addEventListener('mousedown', onMouseDown, false);
    //     container.addEventListener('mouseup', onMouseUp, false);
    // }


}

function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
    if (isshowPic) {
        updatePicView();
        calcMaxh();
    }
    if (!isPrivateSpace && labelRenderer)
        labelRenderer.setSize(window.innerWidth, window.innerHeight);
}

//other
function isAllowEnterPrivate(number, code, callback) {
    $.ajax({
        url: " /nftapi/x/" + id + "/getSecret/" + number + "/" + code,
        type: "GET",
        // headers: {
        //   "Authorization": "Bearer " + token
        // },
        success: function (res) {
            var isallow = false;
            if (res.success) {
                isallow = true;
            }
            if (callback)
                callback(isallow);
        }
    });
}


function shareClick(name, imgObj, imgurl, iscolor, sharelink) {
    $("#sharepic").attr("src", imgurl);
    if (imgObj.imageAttr.orgWidth > imgObj.imageAttr.orgHeight) {
        $("#sharepic").css("width", "100%");
        $("#sharepic").css("height", "auto");
    } else {
        $("#sharepic").css("height", "100%");
        $("#sharepic").css("width", "auto");
    }
    $(".author").html(imgObj.author);
    $(".subject").html(imgObj.title);
    $(".date").html(imgObj.createTime);

    var tmplist = name.split("_")
    $(".project").html("云图里美术馆 · " + tmplist[2] + " 展厅");
    $("#qrcodeDiv").html('')

    if (iscolor) {

        $(".title").html(imgObj.exhibitionName);
        $(".time").html(imgObj.exhibitionStartTime + "至" + imgObj.exhibitionEndTime);

        $("#kicscode").text(imgObj.colorCode.code)
        var colorList = imgObj.colorCode.colorList;
        $("#colorlist div").remove();

        for (var i = 0; i < colorList.length; i++) {
            var ele = colorList[i]
            if (i == colorList.length - 1) {
                $("#colorlist").append(
                    '<div><div class="black"></div>'
                    + '<div style="background:rgb(' + ele.R + ',' + ele.G + ',' + ele.B + ')"></div>'
                    + '</div>'
                )
            } else {
                $("#colorlist").append(
                    '<div style="background:rgb(' + ele.R + ',' + ele.G + ',' + ele.B + ')"></div>'
                )
            }
        }

    } else {
        if (currentSceneInfo != null) {
            $(".title").html(currentSceneInfo.nameCN);
            $(".time").html(currentSceneInfo.startTime + "至" + currentSceneInfo.endTime);
        }
    }
    $('#qrcodeDiv').qrcode({ text: sharelink, width: 192, height: 192, });
    $("#posterImg").attr("src", null)
    $(".toimg, #posterImg").removeClass("imgcenter")
    setTimeout(function () {
        createPoster();
    }, 500);
}


function hideshare() {
    $("#sharepage").fadeOut();
}

function hidePic() {
    // $("#avator").fadeIn();
    // $("#pic-detail").fadeOut(500)
    // $("#detail-close").fadeOut(550)
    $("#large-child").attr("src", '');
    $("#large-child").attr("data-src", '');
    $("#large-child").attr("data-w", '');
    $("#large-child").attr("data-h", '');
    isshowPic = false;

    var picbox = picscene.children[1];
    for (var i = 0; i < picbox.children.length; i++) {
        var child = picbox.children[i]
        child.geometry.dispose();
        child.material.dispose();
    }

    picbox.geometry.dispose();
    for (var j = 0; j < picbox.material.length; j++) {
        picbox.material[j].dispose();
    }

    picscene.remove(picbox);
    picrenderer.clear();

 
    pic_container.removeEventListener("click", onPicContainerClick, false);
}
event.on('hidePic', hidePic);

function getPrivateSceneList(callback) {
    $.ajax({
        url: "/nftapi/x/" + id + "/getSecretList",
        type: "POST",
        success: function (res) {
            if (res.success) {
                if (callback)
                    callback(res.data)
            }
        }
    });
}



var curpic = null;
function onBeginPlayGuide(name) {
    exitSearchView();
    setWeight(speak, 1);
    gotoGuidePic(name);
}

function onexitCallback() {
    TWEEN.removeAll();

    var dir = new THREE.Vector3();
    curpic.getWorldDirection(dir);
    // var backpos = campos.clone().add(dir.normalize().clone().negate().multiplyScalar(200 + cameradistance))
    var backpos = curpic.position.clone().add(dir.clone().normalize().multiplyScalar(400 + cameradistance));

    camera.position.copy(backpos);
    camera.lookAt(controls.target)

    resetControl();
    setWeight(speak, 0)
}

function closeGuidePic() {
    if (ScrollPicture != null) {
        clearInterval(ScrollPicture);
    }
    $("#guide-pic").hide();
    $("#guide-pic-child").attr("src", '');
}

var chainBBack = null;
function onPlayEnded(callback) {

    closeGuidePic()
    setWeight(speak, 0);
    // var campos = camera.position.clone();
    var dir = new THREE.Vector3();
    curpic.getWorldDirection(dir);
    // var backpos = campos.clone().add(dir.normalize().clone().negate().multiplyScalar(200 + cameradistance))
    var backpos = curpic.position.clone().add(dir.clone().normalize().multiplyScalar(400 + cameradistance));
    var campos = curpic.position.clone().add(dir.clone().normalize().multiplyScalar(200));

    var back = new TWEEN.Tween({ cam_x: campos.x, cam_z: campos.z })
        .to({ cam_x: backpos.x, cam_z: backpos.z }, 1000)
        .onStart(function () {
            camera.isNear = false;
        })
        .onUpdate(function (res) {
            camera.position.x = res.cam_x;
            camera.position.z = res.cam_z;
            camera.lookAt(controls.target);
        }).onComplete(function () {
            if (callback) callback();
        }).start();
}

function exit() {
    // $("#floors").fadeIn();
    $("#sceneSwiper").fadeOut();
    $("#sceneSwiper").removeClass("on");
    $("#guide-area").fadeIn();
    resetControl();
}


var touchevent = null;
var startX = 0;
var startY = 0;

function onDocumentTouchStart(event) {
    onMouseDown();
    event.clientX = event.touches[0].clientX;
    event.clientY = event.touches[0].clientY;
    touchevent = event;
}

function onDocumentTouchEnd(event) {
    onMouseUp();
    onMouseClick(touchevent);
}



function initSkyGeo() {
    var geometry = new THREE.SphereBufferGeometry(7500, 40, 40);
    geometry.scale(-1, 1, 1);
    //picloader.load(ismobile ? "/images/xiaodaobg_m.jpg" : "/images/xiaodaobg.jpg",
    picloader.load(ismobile ? "/images/bg_m.jpg" : "/images/bg_pcs.jpg",
        function (texture) {
            var geoma = new THREE.MeshBasicMaterial({
                map: texture
            })
            sky = new THREE.Mesh(geometry, geoma);
            sky.rotateY(Math.PI / 2);
            scene.add(sky)
        }
    )
}

function updatePicView() {
    if (ismobile) {
        picrenderer.setSize((window.innerWidth - 36), (window.innerHeight / 2 - 18), true);
        picrenderer.setViewport(0, 0, (window.innerWidth - 36), (window.innerHeight / 2 - 18))
        piccamera.aspect = (window.innerWidth - 36) / (window.innerHeight / 2 - 18);
    }
    else {
        picrenderer.setSize(window.innerWidth / 2, window.innerHeight, true);
        picrenderer.setViewport(0, 0, window.innerWidth / 2, window.innerHeight)
        piccamera.aspect = window.innerWidth / 2 / window.innerHeight
    }
    piccamera.updateProjectionMatrix();
}

var swiper = null;

function initSwiperUI() {
    if (swiper === null) {
        swiper = new Swiper(".mySwiper", {
            slidesPerView: "auto",
            spaceBetween: 20,
            lazy: true,
            slideToClickedSlide: true,
            pagination: {
                el: ".swiper-pagination",
                type: "progressbar",
                clickable: true
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            }
        });
    }
}

function initSwiperView() {
    $("#sceneSwiper").fadeIn();
    $("#sceneSwiper").addClass("on")
    $(".swiper-slide").click(function () {
        controls.enabled = false;
        if ($(this).hasClass('active')) return;

        exitSearchView();
        $(".swiper-slide").removeClass("active");
        $(this).addClass("active");
        var name = $(this).attr("data-id");
        gotoPic(name);
    });
}


event.on("onSearchItemClick", function (name) {
    gotoPic(name, 0, resetControl);

})

//弹出搜索框
function showSearchView() {
    // $(".search-box").toggleClass("active");
    // if ($(".search-box").hasClass("active"))
    //     $(".search-box").fadeIn();
    // else
    //     $(".search-box").fadeOut();
    // $("#keyword").val();
    event.emit("onShowSearchView");
}

//关闭搜索框
function exitSearchView() {
    $(".search-box").fadeOut();
    $(".search-box").removeClass("active")
}

function onKeywordKeyDown(e) {
    var theEvent = window.event || e;
    var code = theEvent.keyCode || theEvent.which || theEvent.charCode;
    //console.log("code", code)
    if (code == 13) {
        //回车执行查询
        onSearchClick();
    }
}

function autoExitGuide() {
    TWEEN.removeAll();
    if ($(".exit").hasClass("on")) {
        $(".exit").removeClass("on");
        $(".start").addClass("on")
        $("#guide_line").removeClass("on")
    }
    closeGuidePic();
    exitGuide();
    $("#guide-area").hide();
}


var fps = 30;
var now;
var then = Date.now();
var interval = 1000 / fps;
var delta;
var beginCount = false;

var offset = 0;
var start = 0;
function animate() {
    requestAnimationFrame(animate);

    now = Date.now();
    delta = now - then;
    if (delta < interval)
        return;

    then = now - (delta % interval);

    if (!isPrivateSpace) {
        //主会场的资源
        renderer.render(scene, camera);

        if (labelRenderer && isfixtodoor)
            labelRenderer.render(scene, camera);
        if (layergroup && layergroup.visible) {
            if (layerlight) {
                layerlight.position.y += 10;
                if (layerlight.position.y > 150)
                    layerlight.position.y = -150
            }
            if (layer) {
                var o = Math.sin(then * 0.001) * 0.2;
                layer.material.opacity = 0.9 + o;
            }
        }
        TWEEN && TWEEN.update();
    } else {
        renderer.render(privateScene, camera);
        TWEEN && TWEEN.update();
    }

    // spaceTexture.offset.x += 1 / 54;


    // start += 1;
    // //if (start > 5) {

    // $(".video").css("background-position", offset + "px 0");
    // offset -= 300;
    // if (offset < -16200)
    //   offset = 0;
    // start = 0;
    // }


    controls && controls.update();
    if (isshowPic && picrenderer) {
        // if (ismobile)
        //     picrenderer.setViewport(0, 0, window.innerWidth, window.innerHeight / 2);
        // else
        //     picrenderer.setViewport(0, 0, window.innerWidth / 2, window.innerHeight);
        picrenderer.render(picscene, piccamera)
        //piccontrols && piccontrols.update();
    }
    let t = clock.getDelta();
    mixer && mixer.update(t)
    mixers.forEach((m) => {
        m.update(t)
    })
}

var video = null;
function getVideoHtmlByDivName(divname, videourl) {
    var preladStr = ismobile ? "" : 'preload = "auto"';
    var video_html =
        ' <video  muted id="' + divname + '" class="dn" loop ' + preladStr
        + ' webkit-playsinline="true" playsinline="true" '
        + ' x5-video-orientation="portraint" '
        + ' x-webkit-airplay="allow" src="' + videourl + '"> '
        + '  </video> '
    $("body").append(video_html);
}

// var isPlaying = false;
var videowall = null, videowall2 = null, videowall3 = null, videowall4 = null;
function genSceneVideoGeometry() {
    video = video = document.getElementById("scene_video");

    var balckma = new THREE.MeshBasicMaterial({ color: 0x111111 })
    var geothumb = new THREE.PlaneGeometry(1840, 360);
    var geoVideo = new THREE.BoxBufferGeometry(840, 360, 5);

    //图片
    if (video != undefined) {



        var thumbmaterial = new THREE.MeshBasicMaterial({ map: videoList.video1.image ? picloader.load(videoList.video1.image) : null, transparent: true, side: THREE.DoubleSide });
        videowall = new THREE.Mesh(geothumb);
        videowall.material = thumbmaterial
        videowall.position.set(-3000, 240, -833);
        videowall.name = "video"
        videowall.rotateY(Math.PI / 2);
        videowall.visible = true;
        if (!videoList.video1.image) {
            thumbmaterial.opacity = 0;
        }
        objlist.push(videowall);
        msg.add(videowall);



        //视频
        var videoTexture = new THREE.VideoTexture(video);

        videoTexture.minFilter = THREE.LinearFilter;
        videoTexture.format = THREE.RGBAFormat;
        var videoMaterial = new THREE.MeshBasicMaterial({ map: videoTexture });
        videowall2 = videowall.clone();
        videowall2.geometry = geoVideo;
        videowall2.material = [balckma, balckma, balckma, balckma, videoMaterial, balckma];
        videowall2.visible = false;
        video.isloaded = false;

        objlist.push(videowall2);
        msg.add(videowall2);

        const geometry = new THREE.PlaneGeometry(240, 240);
        var playBtnMaterial = new THREE.MeshBasicMaterial({ map: picloader.load(require("./assets/style/images/btn_play.png")), transparent: true, side: THREE.DoubleSide });
        const playBtn = videowall2.clone();
        playBtn.material = playBtnMaterial;
        playBtn.geometry = geometry;

        playBtn.position.x += 5;
        playBtn.visible = true;
        //objlist.push(playBtn);
        //msg.add(playBtn);

        video.addEventListener("play", function () { //播放开始执行的函数
            video.isloaded = true
            videowall2.visible = true;
            playBtn.visible = false;
        });
        video.addEventListener("pause", function () { //播放开始执行的函数
            playBtn.visible = true;
            videowall2.visible = false;
        });


    }
    //right video
    right_video = document.getElementById("scene_video_right");
    console.log(right_video)
    if (right_video != undefined) {
        var right_thumbmaterial = new THREE.MeshBasicMaterial({ map: picloader.load(videoList.video2.image), transparent: true });

        videowall3 = new THREE.Mesh(geothumb.clone(), right_thumbmaterial)
        videowall3.position.set(2955, 240, -833);
        videowall3.rotation.y = -Math.PI / 2
        videowall3.name = "rightvideo"
        videowall3.visible = true;
        if (!videoList.video2.image) {
            right_thumbmaterial.opacity = 0;
        }
        console.log(videoList.video2.image, videowall3)

        var right_videoTexture = new THREE.VideoTexture(right_video);
        right_videoTexture.minFilter = THREE.LinearFilter;
        right_videoTexture.format = THREE.RGBAFormat;
        var right_videoMaterial = new THREE.MeshBasicMaterial({ map: right_videoTexture });
        videowall4 = videowall3.clone();
        videowall4.geometry = geoVideo.clone();
        videowall4.visible = false;
        videowall4.material =
            [balckma, balckma, balckma, balckma,
                right_videoMaterial, balckma];

        right_video.isloaded = false;
        right_video.visible = true;
        right_video.addEventListener("play", function () { //播放开始执行的函数
            right_video.isloaded = true
        });



        objlist.push(videowall3, videowall4);
        msg.add(videowall3, videowall4);

        const geometry = new THREE.PlaneGeometry(240, 240);
        var playBtnMaterial = new THREE.MeshBasicMaterial({ map: picloader.load(require("./assets/style/images/btn_play.png")), transparent: true, side: THREE.DoubleSide });
        const playBtn = videowall4.clone();
        playBtn.material = playBtnMaterial;
        playBtn.geometry = geometry;

        playBtn.position.x -= 5;
        playBtn.visible = true;
        // objlist.push(playBtn);
        // msg.add(playBtn);

        right_video.addEventListener("play", function () { //播放开始执行的函数
            video.isloaded = true
            playBtn.visible = false;
            videowall4.visible = true;
        });
        right_video.addEventListener("pause", function () { //播放开始执行的函数
            playBtn.visible = true;
            videowall4.visible = false;
        });
    }
}

function initPicData(callback) {
    let url = new URL(window.location.href);
    let searchParams = url.searchParams;
    let data = {};
    if (searchParams.has("exhibitionIDs")) {
        data["exhibitionIDs"] = searchParams.get("exhibitionIDs");
    }
    $.ajax({
        url: "/nftapi/x/" + id + "/guide",
        type: "POST",
        data,
        // headers: {
        //   "Authorization": "Bearer " + token
        // },
        success: function (res) {
            if (res.success) {
                //console.log(" load guide data")
                guideList = res.data;
            }
        }
    });

    $.ajax({
        url: "/nftapi/x/" + id + "/getArt",
        data,
        type: "POST",
        success: function (res) {
            if (res.success) {
                var datalist = res.data.artList;
                refreshData(datalist)
                if (callback)
                    callback(true);
            } else {
                if (callback)
                    callback(false);
            }
        }
    });
}

function drawExtraObj() {
    introList.introMain && drawIntroPoster(introList.introMain.image,
        introList.introMain.imageAttr?.orgWidth,
        introList.introMain.imageAttr?.orgHeight,
        new THREE.Vector3(-20, 250, 1091),
        0, -Math.PI / 2, 0, "introMain", "G", 380
    )
    initSkyGeo();
}


function drawIntroPoster(image, orgWidth, orgHeight, position, rx, ry, rz, name, floorId, maxh) {
    var infomainMa = new THREE.MeshBasicMaterial({
        map: picloader.load(image.match(/\?.+/) ? image : image + "1140x1140", function (texture) {
            var w = orgWidth || texture.image.naturalWidth;
            var h = orgHeight || texture.image.naturalHeight;

            //限高不限长
            if (h > maxh) {
                var r = maxh / h;
                w = w * r;
                h = maxh;
            }

            var mesh = new THREE.Mesh(new THREE.PlaneGeometry(w, h), infomainMa)
            mesh.position.copy(position);
            mesh.rotation.set(rx, ry, rz);
            if (name)
                mesh.name = name
            if (floorId)
                mesh.floorId = floorId;
            if (isPrivateSpace)
                pMsg.add(mesh)
            else
                msg.add(mesh);
        })
    });


}

function drawIntroPosterBynode(image, orgWidth, orgHeight, node) {
    var infomainMa = new THREE.MeshBasicMaterial({
        map: picloader.load(image)
    });

    var w = orgWidth;
    var h = orgHeight;
    var maxh = 380;
    //限高不限长
    if (h > maxh)
        if (h > w) {
            var r = maxh / h;
            w = w * r;
            h = maxh;
        }

    //var mesh = new THREE.Mesh(new THREE.PlaneGeometry(w, h), infomainMa)
    node.material = infomainMa;
    node.material.needsUpdate = true;
}

function opendoor(delay) {
    playTurnVideo();
    door.isOpen = true;
    new TWEEN.Tween({ ry: 0, opacity: 0 }).to({ ry: -Math.PI / 2, opacity: 1 }, 500)
        .onUpdate(function (res) {
            door.rotation.y = res.ry;
        }).onComplete(function () {
            setTimeout(function () {
                enterIntoPrivate(sid);
            }, 800);
        })
        .delay(500)
        .start();
}

function showBanEnterPrivate() {
    doorlogo.forEach(function (element) {
        element.visible = false;
    });
    layergroup.visible = true;
    banprivate.visible = true;
}

//handle private objs
var isloadscene = false;
function isNearPrivateSpace() {
    if (isPrivateSpace) return;
    if (!door || door.isOpen) return;

    var dis = moveObj.position.distanceTo(privateDoor.position);
    if (dis < 1000) {
        //open the door;
        // if (isInvite) {
        //   if (door.isOpen) return;

        if (isInvite) {
            privateenter.visible = true;
            layergroup.visible = true;
            doorlogo.forEach(function (element) {
                element.visible = false;
            });
            opendoor(500);
            return
        } else {
            if (invitecode != null) {
                showBanEnterPrivate();
                return;
            }
        }
    }
}

function checkIsAuth() {
    if ($("#scene-ul div").length > 0) {
        fixtodoor();
    } else {
        showBanEnterPrivate();
    }
}

var isfixtodoor = false;
function fixtodoor() {
    isfixtodoor = true;
    $("#door-close").show();
    $("#scene-div").fadeIn();
    $("#avator").fadeOut();
    executeCrossFade(currentBaseAction, stand, 0.1);
    TWEEN.removeAll();
    circle.material.opacity = 0;
    controls.enabled = false;
    $("#rightMenu").fadeOut();
    //autoExitGuide();
    var dir = new THREE.Vector3();
    privateDoor.getWorldDirection(dir)
    var newpos = new THREE.Vector3();
    var dis = ismobile ? 250 : 200;

    newpos.copy(privateDoor.position);
    newpos.add(dir.clone().normalize().multiplyScalar(dis))

    var center = getCenterPoint(door)
    var targetY = privateDoor.position.y + center.y / 2;
    var targetx = privateDoor.position.x + center.x / 2;
    controls.target.set(targetx, targetY, privateDoor.position.z)
    controls.update();
    camera.lookAt(controls.target)

    controls.enabled = false;
    new TWEEN.Tween({
        x: camera.position.x,
        y: camera.position.y,
        z: camera.position.z
    }).to({
        x: newpos.x + center.x / 2,
        y: targetY,
        z: newpos.z + center.z / 2
    }, 300).onUpdate(function (res) {
        camera.position.set(res.x, res.y, res.z)
        camera.lookAt(controls.target)
    }).onStart(function () {
        player.visible = false;
    }).easing(TWEEN.Easing.Quadratic.In).start();
}

function hideprivatescene() {
    $("#door-close").fadeOut();
    $("#scene-div").fadeOut();
    $("#rightMenu").fadeIn();
    $("#avator").fadeIn();
    layergroup.visible = false;
    doorlogo.forEach(function (element) {
        element.visible = true;
    });

    new TWEEN.Tween({ x: camera.position.x, y: camera.position.y, z: camera.position.z })
        .to({ x: moveObj.position.x, y: moveObj.position.y + cameraheight, z: moveObj.position.z + cameradistance }, 300)
        .onUpdate(function (res) {
            camera.position.set(res.x, res.y, res.z)
        }).onStart(function () {
            player.visible = true;
        })
        .onComplete(function () {
            controls.target.set(moveObj.position.x, moveObj.position.y + cameraheight, moveObj.position.z)
            controls.enabled = true;
        }).start();
    // moveObj.position.copy(newpos)
}

function enterIntoPrivate() {

    $("#turn_video").show();
    privateenter.visible = false;
    if (video != null && !video.paused) video.pause();
    if (right_video != null && !right_video.paused) right_video.pause();
    door.rotation.y = -Math.PI / 2;
    TWEEN.removeAll();

    currentfloor = currentfloor = setting.floors["floor_3"]
    //genPosterHtml();
    layergroup.visible = false;
    if (privateScene == null) {
        privateScene = privateScene = initScene();
        isPrivateSpace = true;
        initPrivateScene();
    } else {
        var timeoutId = setTimeout(function () {
            //document.getElementById("turn_video").pause();
            pMsg.add(privateDoor);
            pMsg.add(sky);
            isOutFromPrivateSpace = false;
            privateScene.add(moveObj);
            privateScene.add(circle)
            refreshUI("floor_3S");
            // showTurnVideo();
            isPrivateSpace = true;
            renderer.render(privateScene, camera);
            // $("#turn_video").css("zIndex", 0)
            $("#turn_video").fadeOut();
            updatePlayerPos();
            clearTimeout(timeoutId);
        }, 800);
    }

    $("#floors").hide();

}

function exitPrivate() {

    if (pVideoLeft != null && !pVideoLeft.paused) pVideoLeft.pause();
    if (pVideoRight != null && !pVideoRight.paused) pVideoRight.pause();
    $("#turn_video").fadeIn();

    
   // genPublckPosterHtml();
    TWEEN.removeAll();
    setTimeout(function () {
        layergroup.visible = true;
        privateenter.visible = true;
        $("#floors").show();
        isPrivateSpace = false;
        msg.add(privateDoor);
        msg.add(sky);
        scene.add(moveObj);
        scene.add(circle)
        isOutFromPrivateSpace = true;
        updatePlayerPos();
        $("#turn_video").fadeOut();
        // $("#turn_video").css("zIndex", 0);
        //  document.getElementById("turn_video").pause();
    }, 800);
}

function updatePlayerPos(callback) {
    if (isPrivateSpace) {
        TWEEN.removeAll();
        $("#tips").show();
        $("#tips").fadeOut(3000);
        moveObj.position.set(setting.privateStartPos.x, setting.privateStartPos.y, setting.privateStartPos.z)
        var dir = new THREE.Vector3();
        var newPos = privateDoor.position.clone();

        privateDoor.getWorldDirection(dir)
        newPos.add(dir.clone().multiplyScalar(-300));
        // newPos.x += 50;

        camera.position.y = moveObj.position.y + cameraheight;
        new TWEEN.Tween({ x: moveObj.position.x, z: moveObj.position.z })
            .to({ x: newPos.x, z: newPos.z }, 800)
            .onStart(function () {
                player.rotation.y = 0;
                executeCrossFade(currentBaseAction, walk, 0.1)
                controls.enabled = false;
            })
            .onUpdate(function (res) {
                moveObj.position.z = res.z;
                moveObj.position.x = res.x;
                controls.target.set(moveObj.position.x, moveObj.position.y + cameraheight, moveObj.position.z);
                var campos = moveObj.position.clone().add(dir.clone().normalize().multiplyScalar(-cameradistance));
                camera.position.x = campos.x;
                camera.position.z = campos.z;
                camera.lookAt(controls.target)
                controls.update();
            })
            .onComplete(function () {
                executeCrossFade(currentBaseAction, stand, 0.1)
                controls.enabled = true;
            })
            .start();
        return;
    }
    if (isOutFromPrivateSpace) {

        currentfloor = currentfloor = setting.floors["floor_3"]
        refreshUI("floor_3");

        $(".floor").removeClass("current")
        $("#btn_floor_3").addClass("current")

        player.rotation.y = Math.PI

        var dir = new THREE.Vector3();
        var newPos = privateDoor.position.clone();
        newPos.y = currentfloor.startPos.y;
        privateDoor.getWorldDirection(dir)
        newPos.add(dir.clone().multiplyScalar(300));
        newPos.x += 50;
        moveObj.position.y = newPos.y;
        new TWEEN.Tween({ x: privateDoor.position.x + 50, z: privateDoor.position.z })
            .to({ x: newPos.x, z: newPos.z }, 1200)
            .onStart(function () {
                executeCrossFade(currentBaseAction, walk, 0.1)
                controls.enabled = false;
            })
            .onUpdate(function (res) {
                moveObj.position.z = res.z;
                moveObj.position.x = res.x;
                cameraBeforeFllow();
            })
            .onComplete(function () {
                executeCrossFade(currentBaseAction, stand, 0.1)
                controls.enabled = true;
                $("#rightMenu").fadeIn();
            })
            .start();

    } else if (isInvite) {

        // moveObj.visible = true;
        var currentpath = setting.movepath[2];
        moveObj.position.set(currentpath[0][0], currentpath[0][1], currentpath[0][2]);
        $("#guide-area").hide();

        autoMove(currentpath, 1, currentpath.length - 1, 1500, function () {
            updateFloor(3)
            isNearPrivateSpace();
            $("#guide-area").fadeIn();
            $("#rightMenu").fadeIn();
            if (callback)
                callback();
        })
    } else {
        if (position) {

            updatePositionbyPosition(position,callback)
            

        } else {

            moveObj.position.set(setting.playerStartPos.x, setting.playerStartPos.y, setting.playerStartPos.z)
            camera.position.set(setting.playerStartPos.x, setting.playerStartPos.y + cameraheight, setting.playerStartPos.z + cameradistance);
            camera.lookAt(controls.target);
            $("#rightMenu").fadeIn();
            controls.enabled = true;
            if (callback)
                callback();
        }
    }
}

function updatePositionbyPosition(position, callback) {
    var pic = msg.getObjectByName(position);
    if (pic == undefined || pic == null) return;
    var dir = new THREE.Vector3();
    pic.getWorldDirection(dir)


    var newpos = new THREE.Vector3();
    newpos.copy(pic.position);
    newpos.add(dir.clone().normalize().multiplyScalar(400))
    newpos.y = setting.floors["floor_" + pic.floorId].startPos.y;
    var campos = new THREE.Vector3();
    campos.copy(pic.position);
    campos.add(dir.clone().normalize().multiplyScalar(400 + cameradistance));
    campos.y = pic.position.y;

    controls.target.set(pic.position.x, pic.position.y, pic.position.z);
    camera.position.set(campos.x, campos.y, campos.z);
    camera.lookAt(controls.target);
    controls.update();
    moveObj.position.set(newpos.x, newpos.y, newpos.z);



    player.rotation.copy(new THREE.Euler().setFromQuaternion(pic.quaternion, "YXZ"));


    //player.rotation.y =dir.y//+Math.PI
    $("#rightMenu").fadeIn();
    updateFloor(pic.floorId);
    resetControl();
    if (callback)
        callback();
}

function collectClick(imgObj) {
    console.log(imgObj)
    collect(imgObj, imgObj.isCollection == 1)
  
}
event.on("collectClick", collectClick);
function collect(imgObj, iscancel) {
    // 2、用户收藏接口：/nftapi/x/1/collection/2
    // 最后一个2是作品ID，私密馆作品不能收藏。
    // 3、用户取消收藏接口：/nftapi/x/1/unCollection/2
    var url = ""
    if (iscancel) {
        url = "/nftapi/x/" + id + "/unCollection/" + imgObj.artID
    } else {
        url = "/nftapi/x/" + id + "/collection/" + imgObj.artID
    }

    $.ajax({
        url: url,
        type: "POST",
        // headers: {
        //   "Authorization": "Bearer " + token
        // },
        success: function (res) {
            if (res.success) {
                if (!iscancel) {
                    $("#collect").addClass("on")
                    imgObj.isCollection = 1
                } else {
                    $("#collect").removeClass("on")
                    imgObj.isCollection = 0;
                }
            } else {
                $("#warn").text(res.message)
            }
        },
        error: function (res) {
            $("#warn").text(res.responseJSON.message)
            if (res.responseJSON.errorCode == "401")
                showLogin();
        }
    });
}

function initScenelistlabel(data) {
    var text = document.createElement('div');
    text.className = 'label';
    text.id = 'scenelist'

    var scediv = document.createElement('div');
    scediv.id = 'scene-div';
    text.appendChild(scediv)

    var layer = document.createElement('div');
    layer.id = 'layer';
    scediv.appendChild(layer)

    var bord = document.createElement('div');
    bord.className = 'scene-label';
    bord.id = 'scene-ul';
    scediv.appendChild(bord)

    for (var o in data) {
        if (o != "exhibitionBuildingName") {
            var element = data[o];
            var name = document.createElement('div');
            name.className = 'scene-label';
            //    name.id = 'scene-label-' + i
            name.textContent = element.nameCN;
            name.setAttribute("sid", element.id)
            bord.appendChild(name)
        }
    }
    return text;
}

var invitelink = "https://1.wonderina.com/exhibition"
function initShare({ name, imgObj, imgurl, exhibitionID, showCollect }) {
   
        //生成分享海报
        if (isPrivateSpace) {
            if (privateInviteLink == null) {
                if (inviteError) {
                    $("#warn").show();
                    $("#warn").fadeOut(3000)
                }
                else if (inviteError == "401") {
                    showLogin();
                }
                    
                return;
            }
            imgObj = Object.assign(imgObj, currentSceneInfo != null ? {
                exhibitionName: currentSceneInfo.nameCN,
                exhibitionStartTime: currentSceneInfo.startTime,
                exhibitionEndTime: currentSceneInfo.endTime
            } : {})
            event.emit("share", { name, imgObj, imgurl, iscolor: false, privateInviteLink });
            //shareClick(name, imgObj, imgurl, false, privateInviteLink)
        }
        else {


            var sharelink = invitelink + "?position=" + name + "&exhibitionID=" + exhibitionID;

            event.emit("share", { name, imgObj, imgurl, iscolor: true, showCollect, sharelink });
            // shareClick(name, imgObj, imgurl, true, sharelink)
        }

  
}
event.on("shareClick", initShare);
function initScenelistevent() {
    $("#scene-ul div").each(function () {
        $(this).click(function () {
            var selSid = $(this).attr("sid");
            sid = selSid;
            $("#door-close").hide();
            opendoor(0)
            player.visible = true;
            $("#scene-div").fadeOut();
            $("#rightMenu").fadeIn();
            $("#avator").fadeIn();

            return;
        })
        $(this).on('mouseover', function () {
            $(this).addClass("on")
        })
        $(this).on('mouseleave', function () {
            $(this).removeClass("on")
        })
    });
}

function refreshData(datalist) {
    for (var o in datalist) {
        if (datalist[o].artType == "video") {
            videoList[o] = datalist[o]
        } else {
            if (o.indexOf("intro") > -1) {
                introList[o] = datalist[o]
            } else if (o.indexOf("N_") > -1) {
                artList[o] = datalist[o]
            } else if (o.indexOf("door2") > -1) {
                posterList[o] = datalist[o]
            }
        }
    }
}

var isFirst = true;
//begin loading manager
manager.onLoad = function () {
    if (!isPrivateSpace) {
        if (lastper != 100) return;
    } else {
        if (door.isOpen) {
            $("#turn_video").fadeOut();
        }
    }

    //第一次加载
    if (isFirst) {
        isFirst = false;
        //loadvideo();
        initfloorsButton();
        //initSwiperUI();
        refreshUI("floor_1");
        initGuideUI(onBeginPlayGuide, onPlayEnded, onexitCallback);
        animate();
        $("#process_page").hide();
        $("#avator").removeClass("dn")
        $("#guide-area").removeClass("dn");
        updateUserAvator();
        visitlog();
        $("#process_page").remove();
        updatePlayerPos(function () {
            initevent();
        });

    } else {
        updatePlayerPos();
    }
};

var lastper = 0;
manager.onProgress = function (url, itemsLoaded, itemsTotal) {
    if (!isPrivateSpace) {
        var per = Math.floor(itemsLoaded * 100 / itemsTotal);
        var numobj = document.getElementById("process_num");
        if (per >= lastper)
            lastper = per
        if (lastper > 100) lastper = 100;

        numobj.innerHTML = lastper + "% Loading...";
        $("#process").css("width", (100 - lastper) + "%");
    }
};

function initPicScene() {
    piccontainer = document.getElementById('pic_container');
    picrenderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    console.log(piccontainer, picrenderer)
    picrenderer.setPixelRatio(window.devicePixelRatio);
    // if (ismobile)
    //     picrenderer.setSize(window.innerWidth, window.innerHeight / 2);
    // else
    //     picrenderer.setSize(window.innerWidth / 2, window.innerHeight);
    piccontainer.appendChild(picrenderer.domElement);
    let h = ismobile ? (window.innerHeight / 2) : window.innerHeight;
    piccamera = new THREE.PerspectiveCamera(65,
        ismobile ? window.innerWidth / (window.innerHeight / 2) : window.innerWidth / 2 / window.innerHeight, 1, 1000);
    picscene = new THREE.Scene();
    var ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
    picscene.add(ambientLight);
    piccamera.position.set(0, 0, h / 2 / Math.tan(65 / 2 / 180 * Math.PI));

}
event.on('picInit', initPicScene)
function onPicContainerClick() {
    $("#large-child").attr("src", $("#large-child").attr("data-src"));
    $("#large-show").fadeIn();
    $("#large-show .ina-btn-close").removeClass("dn")

    var Win_r = window.innerWidth / window.innerHeight;
    var w = $("#large-child").attr("data-w");
    var h = $("#large-child").attr("data-h");
    var Pic_r = w / h;
    if (Pic_r > Win_r) {
        $("#large-show").css("overflow-x", "scroll")
        $("#large-child").css("height", "100%");
        $("#large-child").css("width", "auto");
        $("#large-show").css("overflow-y", "hidden")
    } else {
        $("#large-show").css("overflow-y", "scroll")
        $("#large-child").css("width", "100%");
        $("#large-child").css("height", "auto");
        $("#large-show").css("overflow-x", "hidden")
    }
}

function closeLargePic() {
    $("#large-show").hide();
    $("#large-show .ina-btn-close").addClass("dn")
}

function genPublckPosterHtml() {
    $("#sharepage").remove();
    $("body").append(
        ' <div id="sharepage">'
        + ' <div id="share-close" class="ina-btn-close" onclick="hideshare()">'
        + '  <div class="i-close"></div>'
        + ' </div>'
        + ' <div class="dn" id="poster-container">'
        + '   <div class="poster-container">'
        + '    <div class="flex nr">'
        + '     <div class="top">'
        + '        <div class="author"></div>'
        + '       <div class="subject"></div>'
        + '        <div class="date"></div>'
        + '     </div>'
        + '     <div class="pic">'
        + '       <img id="sharepic" class="sharepic" src="">'
        + '     </div>'
        + '     <div class="bottom">'
        + '       <div class="about">'
        + '         <div class="left">'
        + '          <div class="title"></div>'
        + '           <div class="time"></div>'
        + '           <div class="logo">'
        + '            <img src="/images/i-exlogo.png" />'
        + '             <img src="/images/i-logo.png" />'
        + '           </div>'
        + '                     <div class="project"></div>'
        + '        </div>'
        + '        <div id="qrcode">'
        + '          <div id="qrcodeDiv" class="qrcode">'
        + '            </div>'
        + '          </div>'
        + '        </div>'
        + '       <div class="code">'
        + '         <div id="kcis">KCIS - <span id="kicscode"></span></div>'
        + '          <div class="color">'
        + '           <div class="headercolor">'
        + '             <div></div><div></div><div></div><div></div>'
        + '           </div>'
        + '           <div id="colorlist"></div>'
        + '         </div>'
        + '       </div>'
        + '     </div>'
        + '  </div>'
        + '   <img class="hbb" src="/images/hbbian.png" />'
        + '   <div class="shadowtop"></div>'
        + '   <div class="shadowleft"></div>'
        + '  </div>'
        + ' </div> '
        + ' <div class="toimg">'
        + '<div class="container" style="text-align:center;padding-top:0px; width: 100%; height: 100%;">'
        + ' <img id="posterImg" class="img-responsive" style="display:inline-block;" />'
        + '  </div>'
        + '  </div>'
        + '</div>'
    )
}

//private scene
var customModelGroup = new THREE.Group(), pMsg = new THREE.Object3D();
var publicModelGroup = new THREE.Group(), pMsg = new THREE.Object3D();
//var emptywall = new THREE.MeshBasicMaterial({ opacity: 0, transparent: true, color: 0xffffff });
var currentSceneInfo = null, privateInviteLink = null;
let privateObjlist = [];
var privateArtList = {};

function initPrivateScene() {
    getPrivateSceneGuideData(function () {
        refreshUI("floor_3S");
    });
    getInviteCode(function (code) {
        if (code != "") {
            privateInviteLink = "https://1.wonderina.com/exhibition?" + "&sid=" + sid + "&invitecode=" + code
        }
    });
    //console.log(" get scene info")
    getPrivateSceneList(function (data) {
        for (var num in data) {
            if (data[num].id == sid) {
                currentSceneInfo = data[num];
            }
        }
        // console.log(" private data")
        initPrivatePicData(function () {
            privateScene.add(pMsg);
            for (var i = 0; i < setting.privatescene.length; i++) {
                loadPrivateScene(setting.privatescene[i])
            }
            pMsg.add(privateDoor);
            pMsg.add(dummydoor);

            privateObjlist.push(dummydoor)

            privateScene.add(customModelGroup);

            let promises = [];
            for (var i = 0; i < setting.customModel.length; i++)
                !setting.customModel[i].isPublic && promises.push(initCustomModels(setting.customModel[i]));

            Promise.all(promises).then((gltfs) => {
                pMsg.add(sky);
                drawPrivateExtraObj()
            })

        });
    })
}

var pVideoLeft = null, pVideoLThumbWall = null, pVideoLeftWall = null, pVideoRight = null, pVideoRightThumbWall = null, pviderightWall = null;
function drawPrivateExtraObj() {
    var maxh = 660;
    var leftdata = null, rightdata = null;
    if (privateArtList.N_3F_V_1 != undefined)
        leftdata = privateArtList.N_3F_V_1;
    if (privateArtList.N_3F_V_2)
        rightdata = privateArtList.N_3F_V_2;

    var geoVideo = new THREE.BoxBufferGeometry(1540, maxh, 5);
    var balckma = new THREE.MeshBasicMaterial({ color: 0x111111 })
    var geothumb = new THREE.PlaneGeometry(1540, maxh);
    var leftpos = { x: -1600, y: 1380, z: -4425 }
    var rightpos = { x: 1600, y: 1380, z: -4425 }

    if (leftdata != null)
        if (leftdata.artType == "video") {
            getVideoHtmlByDivName("p_video", leftdata.file);

            pVideoLeft = document.getElementById("p_video");
            var thumbmaterial = new THREE.MeshBasicMaterial({ map: picloader.load(leftdata.image) });
            //图片
            pVideoLThumbWall = new THREE.Mesh(geothumb, thumbmaterial);
            pVideoLThumbWall.position.copy(leftpos)
            pVideoLThumbWall.name = "video"
            pVideoLThumbWall.visible = true;

            //视频
            var videoTexture = new THREE.VideoTexture(pVideoLeft);
            videoTexture.minFilter = THREE.LinearFilter;
            videoTexture.format = THREE.RGBAFormat;
            var videoMaterial = new THREE.MeshBasicMaterial({ map: videoTexture });
            pVideoLeftWall = pVideoLThumbWall.clone();
            pVideoLeftWall.geometry = geoVideo;
            pVideoLeftWall.visible = false;
            pVideoLeftWall.material =
                [balckma, balckma, balckma, balckma,
                    videoMaterial, balckma];

            pVideoLeft.isloaded = false
            pVideoLeft.addEventListener("play", function () { //播放开始执行的函数
                pVideoLeft.isloaded = true
            });
            pVideoLeft.addEventListener('playing', function (e) {
                pVideoLeftWall.visible = true;
            });
            privateObjlist.push(pVideoLThumbWall, pVideoLeftWall);
            pMsg.add(pVideoLThumbWall, pVideoLeftWall);
        } else if (leftdata.artType == "image") {
            drawIntroPoster(leftdata.orgImage, leftdata.imageAttr.orgWidth, leftdata.imageAttr.orgHeight, leftpos, 0, 0, 0, "pLeftPoster", null, maxh)
        }

    if (rightdata != null)
        if (rightdata.artType == "video") {
            getVideoHtmlByDivName("p_video_right", rightdata.file);

            pVideoRight = document.getElementById("p_video_right");
            var thumbmaterial = new THREE.MeshBasicMaterial({ map: picloader.load(rightdata.image) });

            //图片
            pVideoRightThumbWall = new THREE.Mesh(geothumb, thumbmaterial);
            pVideoRightThumbWall.position.copy(rightpos)
            pVideoRightThumbWall.name = "rightvideo"
            pVideoRightThumbWall.visible = true;

            //视频
            var videoTexture = new THREE.VideoTexture(pVideoRight);
            videoTexture.minFilter = THREE.LinearFilter;
            videoTexture.format = THREE.RGBAFormat;
            var videoMaterial = new THREE.MeshBasicMaterial({ map: videoTexture });
            let pVideoRightWall = pVideoRightThumbWall.clone();
            pVideoRightWall.geometry = geoVideo;
            pVideoRightWall.visible = false;
            pVideoRightWall.material =
                [balckma, balckma, balckma, balckma,
                    videoMaterial, balckma];

            pVideoRight.isloaded = false
            pVideoRight.addEventListener("play", function () { //播放开始执行的函数
                pVideoRight.isloaded = true
            });
            pVideoRight.addEventListener('playing', function (e) {
                pVideoRightWall.visible = true;
            });
            privateObjlist.push(pVideoRightThumbWall, pVideoRightWall);
            pMsg.add(pVideoRightThumbWall, pVideoRightWall);
        } else if (rightdata.artType == "image") {
            drawIntroPoster(rightdata.orgImage, rightdata.imageAttr.orgWidth, rightdata.imageAttr.orgHeight, rightpos, 0, 0, 0, "pRightPoster", null, maxh)
        }

}

var inviteError = 0;
function getInviteCode(callback) {

    $.ajax({
        url: "/nftapi/x/" + id + "/getInviteCode/" + sid + "?invitePeopleNum=1&validDays=7",
        type: "get",
        success: function (res) {
            if (res.success) {
                var code = res.data.code
                //console.log("code" + code)

                if (callback)
                    callback(code);
            } else {
                // alert(res.message);
                $("#warn").text(res.message)
                inviteError = res.errorCode
                if (callback)
                    callback("");
            }
        },
        error: function (res) {
            $("#warn").text(res.responseJSON.message);
            inviteError = res.responseJSON.errorCode
            //console.log(res)
            // $("#logincenter").show();
        }
    });
}

function initPrivatePicData(callback) {
    $.ajax({
        url: "/nftapi/x/" + id + "/getS_Art/" + sid,
        type: "POST",
        // headers: {
        //   "Authorization": "Bearer " + token
        // },
        success: function (res) {
            if (res.success) {
                privateArtList = res.data.artList;
                if (callback)
                    callback(true);
            } else {
                if (callback)
                    callback(false);
            }
        }
    });
}

function loadPrivateScene(curscene) {
    var scenetype = curscene.scenetype.toLowerCase();
    if (curscene.enable) {
        switch (scenetype) {
            case "fbx":
                var rootPath = curscene.root;
                var url = curscene.url || (rootPath + "/" + curscene.name);
                loader.load(url, function (object) {
                    object.scale.set(curscene.scale, curscene.scale, curscene.scale);
                    object.position.set(curscene.pos.x, curscene.pos.y, curscene.pos.z)
                    object.name = curscene.type;

                    if (curscene.type == "main") {
                        pMsg.add(object);
                        var startnode = object;
                        startnode.traverse(function (obj) {
                            initPrivateSceneInfo(obj);
                        });

                        if (curscene.picnode != undefined && curscene.picnode != "") {
                            var picNode = privateScene.getObjectByName(curscene.picnode)
                            if (picNode) {
                                picNode.traverse(function (obj) {
                                    initPrivatePicInfo(obj);
                                });

                            }
                        }
                    }
                    else {
                        var startnode = object.getObjectByName("F3")
                        pMsg.add(startnode)
                        startnode.traverse(function (obj) {
                            initPrivateSceneInfo(obj);
                        });
                    }

                });
                break;
        }
    }
}

function getPrivateSceneGuideData(callback) {
    $.ajax({
        url: "/nftapi/x/" + id + "/getS_guide/" + sid,
        type: "GET",
        // headers: {
        //   "Authorization": "Bearer " + token
        // },
        success: function (res) {
            if (res.success) {
                guideList.floor_3S = res.data;
                if (callback)
                    callback();
            } else {
                // $("#warn").show();
                // setTimeout(function () {
                window.location.href = "/main.html"
                //}, 5000);
            }
        }
    });
}

var floor3SNum = 0;
function initPrivatePicInfo(node) {
    if (node instanceof THREE.Mesh) {
        if (node.name.startsWith(setting.picture_start_name)) {

            var tmplist = node.name.split('_');
            var floor = tmplist[1];

            if (floor != "F3") {
                PrivatefreeNode(node);
                return;
            }

            var num = Number(tmplist[2])
            if (num >= 27 && num <= 47) return;

            var room = "S";
            floor3SNum++;
            var num = floor3SNum;
            floor = "3F"

            if (floor3SNum == 70) {
                var dir = new THREE.Vector3();
                node.getWorldDirection(dir)
                node.position.add(dir.multiplyScalar(5))
            }
            var artname = "N_" + floor + "_" + room + "_" + num
            var imgObj = privateArtList[artname]

            if (imgObj && imgObj != undefined && imgObj != null && imgObj != "null") {
                var text = "";
                if (imgObj.author != null && imgObj.author != "")
                    text = makeTips(imgObj.title + "\n ⌜" + imgObj.author + "⌟ ");
                else
                    text = makeTips(imgObj.title);
                var box = checkbox.setFromObject(text);
                var size = new THREE.Vector3()
                box.getSize(size);
                var max_w = size.x;
                var text_r = 1.0;

                if (size.x > s_l * 100) {
                    max_w = s_l * 100;
                    text_r = max_w / size.x;
                    var sx = text.scale.x * text_r;
                    var sy = text.scale.y * text_r;
                    text.scale.set(sx, sy, 1);
                }
                text.rotation.copy(node.rotation)
                text.position.set(node.position.x, node.position.y - text.scale.y / 2 - 135, node.position.z + 2)
                pMsg.add(text);

                var image = imgObj.image;
                var imgarr = imgObj.imageAttr;
                if (image != null) {

                    s_l = 2.5
                    var w_s = s_l;
                    if (imgObj.type == "#120" || imgObj.type == "2280x2280")
                        w_s = s_l;
                    else if (imgObj.type == "#100" || imgObj.type == "1140x1140")
                        w_s = s_l * s_m_r;
                    else if (imgObj.type == "#15" || imgObj.type == "750x750") {
                        w_s = s_l * s_s_r
                    }



                    node.material = picboxma;
                    node.name = artname;
                    node.floorId = floor.substring(0, 1);

                    //显示画
                    var ma = new THREE.MeshBasicMaterial({
                        map: picloader.load(image.match(/\?.+/) ? image : image + "1140x1140", (texture) => {
                            var h = imgarr?.orgHeight || texture.image.naturalHeight;
                            var w = imgarr?.orgWidth || texture.image.naturalWidth;

                            if (w == h) {
                                node.scale.x = w_s;
                                node.scale.y = w_s;
                            } else if (w > h) {
                                node.scale.x = w_s;
                                node.scale.y = w_s * h / w
                            } else {
                                node.scale.x = w_s * w / h;
                                node.scale.y = w_s;
                            }
                            var pic = new THREE.Mesh(new THREE.PlaneGeometry((1 - 15 / (node.scale.x * 100)) * 100, (1 - 15 / (node.scale.y * 100)) * 100), ma);
                            node.add(pic)
                            pic.name = node.name;
                            pic.floorId = node.floorId;
                            pic.position.z += 52

                            privateObjlist.push(node)
                        }),
                        transparent: false,
                    })



                } else {
                    delete privateArtList[artname];
                }
            } else
                //  if (floor != "3F")
                PrivatefreeNode(node);
            // else
            //   node.visible = true
        }
    }

    for (var i = 0; i < node.children.length; i++) {
        if (node.children[i] && node.children[i].length > 0) initPicInfo(node.children[i]);
    }
}

function initPrivateSceneInfo(node) {

    if (node instanceof THREE.Mesh) {
        if (node.name.toLowerCase().indexOf('f3neiqiang') > -1 || node.name.toLowerCase().indexOf('f3qiang') > -1) {
            var dummyWall = node.clone();
            dummyWall.scale.set(0.925, 0.6, 0.925);
            dummyWall.material = wallma
            dummyWall.name = "dummy_" + node.name.toLowerCase();
            privateScene.add(dummyWall);
            // privateCrashlist.push(dummyWall);
            privateObjlist.push(node, dummyWall)
        }

        if (setting.floornames.indexOf(node.name) > -1) {
            var ma = node.material;
            ma.emissive.set(0x303030);
            ma.needsUpdate = true;
            privateObjlist.push(node)
            currentfloor = currentfloor = node;
            currentfloor.startPos = setting.floors.floor_3.startPos;
        }
    }

    for (var i = 0; i < node.children.length; i++) {
        if (node.children[i] && node.children[i].length > 0) initInfo(node.children[i]);
    }
}


// 检索
function onSearchClick() {
    if ($("#guide-status .exit").hasClass("on"))
        autoExitGuide();
    else
        $("#guide-area").hide();

    var keyword = $("#keyword").val();
    $("#res_keyword").text(keyword);
    $(".swiper-wrapper .swiper-slide").remove();
    var res_count = 0;
    var dataList = isPrivateSpace ? privateArtList : artList;
    if (keyword && keyword.length > 0) {
        for (var o in dataList) {
            if (dataList[o].title != null && dataList[o].author != null && dataList[o].description != null)
                if (dataList[o].title.indexOf(keyword) >= 0 || dataList[o].author.indexOf(keyword) >= 0 || dataList[o].description.indexOf(keyword) >= 0) {
                    res_count++;
                    $(".swiper-wrapper").append('<div class="swiper-slide" data-id="' + o + '">' +
                        '<div class="scene-item">' +
                        '  <img src="' + dataList[o].image + '" />' +
                        '</div>' +
                        ' <div class="title"> ' + dataList[o].title + ' </div>' +
                        '</div>');

                }
        }
    }
    $("#res_count").text(res_count);

    initSwiperView();

    setTimeout(function () {
        swiper.update(true);
        swiper.updateActiveIndex(0);
    }, 800);

    // $(".search-box").fadeOut();
}

function updatePicDetail({ refKey, imgObj, imgurl, showCollect }) {

    textureLoader.load(imgurl, function (texture) {
        pic_container.addEventListener("click", onPicContainerClick, false);
        updatePicView();

        // $("#pic-detail").fadeIn(500)
        // $("#loading").hide();
        // $('#detail-close').fadeIn(600);



        // $("#author").text(imgObj.author)
        // $("#title").text(imgObj.title)
        // $("#des").html(imgObj.description)
        // $("#cratetime").text(imgObj.createTime)


        var imgarr = imgObj.imageAttr;
        var w = texture.image.naturalWidth
        var h = texture.image.naturalHeight;

        var maxh = ismobile ? window.innerHeight / 2 : window.innerHeight;
        var maxw = maxh * piccamera.aspect;
        let ratio = w / h;
        if (ratio > piccamera.aspect) {
            h = h * maxw / w;
            w = maxw;
        } else if (ratio < piccamera.aspect) {
            w = w * maxh / h;
            h = maxh;
        } else if (ratio == piccamera.aspect) {
            w = Math.min(maxh, maxw);
            h = Math.min(maxh, maxw);
        }

        var picgeo = new THREE.BoxBufferGeometry(w, h, 5);
        var picbox = new THREE.Mesh(picgeo, [
            picboxma,
            picboxma,
            picboxma,
            picboxma,
            new THREE.MeshBasicMaterial({ color: '#ffffff' }),
            picboxma
        ]);
        console.log(picscene)
        picscene.add(picbox)
        if (ismobile)
            picbox.position.set(0, 0, 6)
        else
            picbox.position.set(20, 0, 6)

        var picma = new THREE.MeshBasicMaterial({ transparent: true, map: texture });
        var picgeo = new THREE.PlaneGeometry(w, h);
        var pic = new THREE.Mesh(picgeo, picma)
        pic.position.set(0, 0, 6)
        picbox.add(pic)
        calcMaxh();
        new TWEEN.Tween({ d: Math.PI, s: 0.3 })
            .to({ d: 0, s: 0.8 }, 1200)
            .onUpdate(function (res) {
                picbox.rotation.y = res.d
                picbox.scale.set(res.s, res.s, res.s)
            }).start();

        $("#large-child").attr("data-src", imgurl);
        $("#large-child").attr("data-w", w);
        $("#large-child").attr("data-h", h);
        event.emit("detailFinish");

    })
    if (showCollect) {
        $("#collect").removeClass("on")
        if (imgObj.isCollection == 1) {
            $("#collect").addClass("on")
        }
       
    }


}
event.on("updatePicDetail", updatePicDetail);
var isshowPic = false;
function showPic(obj, showCollect) {
    if (obj.distance < 2000) {
        //$("#avator").hide();
        TWEEN.removeAll();
        executeCrossFade(currentBaseAction, stand, 0.2);
        //  var namt = obj.name;
        var refKey = obj.object.name;
        if (refKey == undefined) return;

        var imgObj = isPrivateSpace ? privateArtList[refKey] : artList[refKey]
        if (imgObj == undefined) return;

        var imgurl = imgObj.orgImage;
        if (imgurl == undefined || imgurl == "") return;

        // $("#loading").css("display", "flex")

        isshowPic = true;
        event.emit("detail", { refKey, imgObj, imgurl, showCollect })

    }
    return false;
}

function gotoPic(name, aniTime, callback) {
    TWEEN.removeAll();
    var pic = isPrivateSpace ? pMsg.getObjectByName(name) : msg.getObjectByName(name);
    var chain = gotoObject(pic, aniTime, callback)
    chain.start();
}

function gotoGuidePic(name) {
   
    TWEEN.removeAll();
    controls.enabled = false;
    player.visible = true;
    var pic = isPrivateSpace ? pMsg.getObjectByName(name) : msg.getObjectByName(name);
    if (pic == undefined || pic == null) return;
    curpic = pic;

    var back = null, chainA = null, chainB = null;

    var dir = new THREE.Vector3();
    pic.getWorldDirection(dir);

    var backpos = pic.position.clone().add(dir.clone().normalize().multiplyScalar(400 + cameradistance));
    var campos = pic.position.clone().add(dir.clone().normalize().multiplyScalar(200));

    if (camera.isNear) {
        //回到原来的位置
        back = new TWEEN.Tween({ cam_x: campos.x, cam_z: campos.z })
            .to({ cam_x: backpos.x, cam_z: backpos.z }, 1000)
            .onStart(function () {
                camera.isNear = false;
                controls.target.set(pic.position.x, pic.position.y, pic.position.x)
                controls.update();
                camera.position.x = campos.x;
                camera.position.z = campos.z;
                camera.lookAt(controls.target);
                closeGuidePic();
            })
            .onUpdate(function (res) {
                camera.position.x = res.cam_x;
                camera.position.z = res.cam_z;
                camera.lookAt(controls.target);
            })
    }

    chainA = gotoObject(pic);
    chainB = new TWEEN.Tween({ cam_x: backpos.x, cam_z: backpos.z })
        .to({ cam_x: campos.x, cam_z: campos.z }, 5000)
        .onStart(function () {
            camera.isNear = true;
        })
        .onUpdate(function (res) {
            camera.position.x = res.cam_x;
            camera.position.z = res.cam_z;
            camera.lookAt(controls.target)
        }).onComplete(function () {
            var imgurl = findImgUrlByName(name);
            showGuidePic(imgurl);
        }).delay(600)

    if (back != null) {
        back.chain(chainA, chainB).start();
    } else
        chainA.chain(chainB).start();
}


function findImgUrlByName(name) {
    if (name == undefined) return;
    var imgObj = isPrivateSpace ? privateArtList[name] : artList[name]

    var imgurl = "";
    if (imgObj != undefined) {
        imgurl = imgObj.orgImage;
        return imgurl;
    }

    if (imgurl == "") {
        for (var o in posterList) {
            if (o == name) {
                imgurl = posterList[o].orgImage;
                return imgurl;
            }
        }
    }

    if (imgurl == "") {
        for (var o in introList) {
            if (o == name) {
                imgurl = introList[o].orgImage;
                return imgurl;
            }
        }
    }
    return "";
}


function genPosterHtml() {
    $("#sharepage").remove();
    $("body").append(
        '  <div id="sharepage" class="sharepage"> '
        + '  <div id="share-close" class="share-close ina-btn-close" onclick="hideshare()">'
        + '    <div class="i-close"></div>'
        + '  </div>'
        + '  <div class="dn" id="poster-container">'
        + '    <div class="poster-container">'
        + '     <div class="flex nr">'
        + '       <div class="top">'
        + '         <img class="corner" src="/images/i-corner.png" />'
        + '         <div class="author"></div>'
        + '         <div class="subject"></div>'
        + '          <div class="date"></div>'
        + '        </div>'
        + '        <div class="pic pic2">'
        + '         <img id="sharepic" class="sharepic" src="">'
        + '       </div>'
        + '        <div class="bottom bottom2">'
        + '         <div class="about">'
        + '          <div class="left">'
        + '             <div class="title"></div>'
        + '             <div class="time"></div>'
        + '              <div class="logo">'
        + '                <img src="/images/i-exlogo.png" />'
        + '                <img src="/images/i-logo.png" />'
        + '              </div>'
        + '              <div>云图里美术馆 · 3S VIP 展厅</div>'
        + '            </div>'
        + '            <div id="qrcode">'
        + '             <img id="qrcodelogo" src="/images/i-door.png" />'
        + '              <div id="qrcodeDiv" class="qrcode">'
        + '              </div>'
        + '              <div class="yaoting">限时特邀</div>'
        + '           </div>'
        + '         </div>'
        + '        </div>'
        + '     </div>'

        + '     <img class="hbb" src="/images/hbbian-b.png" />'
        + '     <div class="shadowtop"></div>'
        + '     <div class="shadowleft"></div>'
        + '    </div>'
        + '  </div>'
        + '  <div class="toimg">'
        + '    <div class="container" style="text-align:center;padding-top:0px; width: 100%; height: 100%;">'
        + '      <img id="posterImg" class="img-responsive" style="display:inline-block;" />'
        + '    </div>'
        + '  </div>'
        + '  </div> ');

}


// audio

var allMusic = [], Index = 0;
// var audiolist = [];
var myAudio = new Audio();
var li_per_w = "8px";
var currentLine = 0, lrcTime = [], lrc = [];
var iscallback = false;
var isEndCallback = false;

function MusicPlayFn(Index) {

    iscallback = false;
    isEndCallback = false;
    $.ajax({
        type: "GET",
        url: allMusic[Index].subtitle,
        dataType: "text",
        success: function (data) {
            var i = 0;
            var lrclist = data.split("\n");
            currentLine = 0, lrcTime = [], lrc = [];
            for (var line_index in lrclist) {
                var line = lrclist[line_index];
                var tmpstr = line.split("]");
                var key = tmpstr[0];
                var text = tmpstr[1];
                lrcTime[i] = parseFloat(key.substr(1, 3)) * 60 + parseFloat(key.substring(4, 10));//00:00.000转化为00.000格式
                lrc[i] = text;
                i++;
            }

            lrcTime[lrcTime.length] = lrcTime[lrcTime.length - 1] + 3;
        }
    });


    myAudio.src = allMusic[Index].audio;
    myAudio.load();
    myAudio.currentTime = 0;
    myAudio.name = allMusic[Index].NO;

    // myAudio.play();

    $("#lyc").text('');
    $("#lyc").fadeOut();
    // $("#lyc").css("opacity", 0)
}

var isStartGuide = false;
function refreshUI(floorId) {
    Index = 0;
    allMusic = [];
    $("#guide_line ul li").remove();
    if (guideList[floorId] == undefined) {
        $("#guide-area").hide();
        return;
    }

    allMusic = guideList[floorId];
    //allMusic.sort((a,b)=>a.sort>b.sort?1:-1);
    console.log(allMusic)
    if (allMusic.length == 0) {
        $("#guide-area").hide();
        return;
    } else {
        $("#guide-area").show();
    }

    li_per_w = 1 / allMusic.length * 100

    for (var i = 0; i < allMusic.length; i++) {
        var url = '', imgstr = '';
        url = findImgUrlByName(allMusic[i].NO);
        if (url != '')
            imgstr = ' <div class="img"> <img att-no="' + allMusic[i].NO + '" src="' + url + '" /></div>'

        $("#guide_line ul").append(
            '<li>'
            + ' <div class="step">'
            + imgstr
            // + '  <div class="img"> <img src="' + allMusic[i].file + '" /></div>'
            + '</div>'
            + '</li> '
        )
        $("#guide_line li").eq(i).css("left", (i * 100 / allMusic.length) + "%");
    }
}

function exitGuide() {
    if (myAudio)
        myAudio.pause();
    $("#lyc_area").fadeOut();
    $("#rightMenu").fadeIn();
    $("#avator").show();
    $("#guide_line").removeClass("on")
    $("#guide-status .exit").removeClass("on");
    $("#guide-status .start").addClass("on")
    resetControl();

}

function initGuideUI(canplayCallback, onPlayEndCallback, onexitCallback) {
    $(".guide").click(function () {
        //TWEEN.removeAll();
        executeCrossFade(currentBaseAction, stand, 0.1)
        //exitSearchView();
        $(this).siblings().addClass("on");
        $(this).removeClass("on");
        $("#guide_line").toggleClass("on")
      
        if ($(".exit").hasClass("on")) {
            $("#avator").hide();
            $("#rightMenu").fadeOut();
            $("#lyc_area").css("display", "flex");
            Index = 0;
            $("#guide_line li").css("width", "8px");
            MusicPlayFn(Index);
        } else {
            //退出
            exitGuide()
            if (typeof onexitCallback === "function")
                onexitCallback();
        }
    })


    myAudio.addEventListener('canplay', function () {
        if (typeof canplayCallback === "function" && !iscallback) {
            canplayCallback(myAudio.name);
            iscallback = true
            myAudio.play();
        }
    });

    myAudio.addEventListener('timeupdate', function () {
        $("#guide_line li").eq(Index).css("width",
            (myAudio.currentTime / myAudio.duration * 100) / allMusic.length + '%');

        if ((myAudio.duration - myAudio.currentTime < 2.5) && !isEndCallback) {
            if (typeof onPlayEndCallback === "function") {
                isEndCallback = true;
                //console.log(" back to ori")
                onPlayEndCallback();
            }
        }

        var currentTime = myAudio.currentTime;
        for (var j = currentLine, len = lrcTime.length; j < len; j++) {
            var next = j + 1;
            //   console.log(currentTime + " " + lrcTime[j] + " " + lrcTime[next])
            if (currentTime < lrcTime[next] && currentTime > lrcTime[j]) {
                currentLine = j;
                if (lrc[currentLine] != "") {
                    $("#lyc").fadeIn();
                    $("#lyc").text(lrc[currentLine]);
                } else {
                    $("#lyc").fadeOut();
                }
                break;
            }
        }
    });

    myAudio.addEventListener("ended", function () {
        Index++;
        if (Index == allMusic.length) {
            //自动退出导览
            exitGuide()
            onexitCallback();
            $("#avator").show();
            return;
        }
        MusicPlayFn(Index);
    });

    $('#volumeNode').click(function () {
        $(this).toggleClass("on")
        var destvol = 0;
        if ($(this).hasClass("on"))
            destvol = 1;
        else
            destvol = 0;

        new TWEEN.Tween({ vol: myAudio.volume }).to({ vol: destvol }, 800).onUpdate(function (res) {
            myAudio.volume = res.vol;
        }).start();

        return false;
    })

    $("#guide_line").click(function (e) {
        var ev = e || event;

        var clickIndex = Math.floor((ev.clientX - this.offsetLeft) / ($("#guide_line").width() / allMusic.length))
        if (clickIndex != Index) {
            if (Index < clickIndex) {
                for (var i = Index; i < clickIndex; i++) {
                    $("#guide_line li").eq(i).css("width", li_per_w + "%");
                }
                if (typeof onPlayEndCallback === "function")
                    onPlayEndCallback();
            } else {
                for (var i = Index; i > clickIndex; i--) {
                    $("#guide_line li").eq(i).css("width", "8px");
                }
                if (typeof onPlayEndCallback === "function")
                    onPlayEndCallback();
            }
            Index = clickIndex;
            // myAudio.src = allMusic[Index].MusicSrc;
            // myAudio.play();
            MusicPlayFn(Index)
        }

        // return;
        if (myAudio.duration && myAudio.currentTime) {
            var offset = $("#guide_line li").eq(Index).offset();
            var offwidth = $("#guide_line").width() / allMusic.length;
            myAudio.currentTime = myAudio.duration * ((ev.clientX - offset.left) / offwidth);
            //myAudio.currentTime = myAudio.duration * ((ev.clientX - this.offsetLeft) / (this.offsetWidth / allMusic.length));
            // $(".trueLine").css("width", ((ev.clientX - this.offsetLeft) / this.offsetWidth) * 100 / allMusic.length + '%');
        }
    });
}

//scroll pic
var ScrollPicture = null;
function scrllHeight(parent, child) {
    parent.scrollTop = 0;
    ScrollPicture = setInterval(function () {

        if (parent.scrollTop + parent.clientHeight >= child.scrollHeight) {
            clearInterval(ScrollPicture);
        } else {
            parent.scrollTop++;
        }
    }, 40);
}

function scrllWidth(parent, child) {
    parent.scrollLeft = 0;
    ScrollPicture = setInterval(function () {

        if (parent.scrollLeft + parent.clientWidth >= child.scrollWidth) {
            clearInterval(ScrollPicture);
        } else {
            parent.scrollLeft++;
            //parent.scrollLeft += 10;
        }
    }, 40);
}

function showGuidePic(url) {
    if (url == undefined || url == "") return;
    var parent = document.getElementById('guide-pic');
    var child = document.getElementById('guide-pic-child');

    var img = new Image();
    img.src = url;
    child.src = url;

    if (ScrollPicture)
        clearInterval(ScrollPicture);
    img.onload = function () {

        $("#guide-pic").fadeIn(500);
        var Win_r = window.innerWidth / window.innerHeight;
        var Pic_r = child.width / child.height;
        if (Pic_r > Win_r) {
            maxHeigt();
            scrllWidth(parent, child);
        } else {
            maxWidth();
            scrllHeight(parent, child);
        }
    };
}

function maxWidth() {
    $("#guide-pic").css("overflow-y", "scroll")
    $("#guide-pic-child").css("width", "100%");
    $("#guide-pic-child").css("height", "auto");
    $("#guide-pic").css("overflow-x", "hidden")
}
function maxHeigt() {
    $("#guide-pic").css("overflow-x", "scroll")
    $("#guide-pic-child").css("height", "100%");
    $("#guide-pic-child").css("width", "auto");
    $("#guide-pic").css("overflow-y", "hidden")
}

function playTurnVideo() {

    turnvideo.load();
    turnvideo.play();
    $("#turn_video").show();
    var videoTexture = new THREE.VideoTexture(turnvideo);
    videoTexture.minFilter = THREE.LinearFilter;
    videoTexture.format = THREE.RGBAFormat;

    space.material = new THREE.MeshBasicMaterial({
        map: videoTexture,
        side: THREE.DoubleSide
    })

}


function GetQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
}

function updateUserAvator() {
    $.ajax({
        url: "/nftapi/getDigitalData",
        type: "GET",
        success: function (res) {
            if (res.success) {
                var userinfo = res.data.userIndex
                var avator = userinfo.avatar;
                $("#nofaceimg").attr("src", avator)
                $("#nofaceimg").removeClass("img_avator");
                $("#usercenter").attr("href", "https://did.wonderina.com/user-center/home")
            }
        },
        error: function (res) {
            $("#warn").text("");
            $("#usercenter").off("click").on("click", showLogin)
        }
    });
}

function is_mobile() {
    var regex_match = /(nokia|iphone|android|motorola|^mot-|softbank|foma|docomo|kddi|up.browser|up.link|htc|dopod|blazer|netfront|helio|hosin|huawei|novarra|CoolPad|webos|techfaith|palmsource|blackberry|alcatel|amoi|ktouch|nexian|samsung|^sam-|s[cg]h|^lge|ericsson|philips|sagem|wellcom|bunjalloo|maui|symbian|smartphone|midp|wap|phone|windows ce|iemobile|^spice|^bird|^zte-|longcos|pantech|gionee|^sie-|portalmmm|jigs browser|hiptop|^benq|haier|^lct|operas*mobi|opera*mini|320x320|240x320|176x220)/i;
    var u = navigator.userAgent;
    if (null == u) {
        return true;
    }
    var result = regex_match.exec(u);
    if (null == result) {
        return false
    } else {
        return true
    }
}

//判断访问终端
var browser = function () {
    var u = navigator.userAgent, app = navigator.appVersion;
    return {
        // trident: u.indexOf('Trident') > -1, //IE内核
        // presto: u.indexOf('Presto') > -1, //opera内核
        // webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
        // gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,//火狐内核
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
        android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android终端
        iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
        iPad: u.indexOf('iPad') > -1, //是否iPad
        webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
        weixin: u.indexOf('MicroMessenger') > -1, //是否微信 （2015-01-22新增）
    };
}();

function visitlog() {
    $.ajax({
        url: "/nftapi/x/" + id + "/visitLog",
        type: "POST"
    });
}

function showLogin() {
    $("#warn").show();
    $("#warn").fadeOut(3000)
    if ($("#login").attr("src") == "")
        $("#login").attr("src", "https://did.wonderina.com/user-center/login")

    $("#logincenter").fadeIn();
    window.addEventListener('message', function (e) {
        if (e.origin == "https://did.wonderina.com") {
            if (e.data.code == 200) {
                updateUserAvator();
                $("#container").show();
                $("#logincenter").hide();
                $("#login").attr("src", "");
                getInviteCode(function (code) {
                    if (code != "") {
                        privateInviteLink = "https://1.wonderina.com/exhibition?" + "&sid=" + sid + "&invitecode=" + code
                    }
                });
            } else {
                $("#container").show();
                $("#logincenter").hide();
            }
        }
    }, false);
    $("#container").fadeOut();
}

function calcMaxh() {
    var maxh;
    if (ismobile)
        maxh = $(".main-right").height() - 65 * 3 - 24 - 56;
    else
        maxh = $(".main-right").height() * 0.916 - 65 * 3 - 24 - 84;
    $("#des").css("max-height", maxh + "px");
}
var steplength = 16.0;

var dragTimeDown = 0;
var dragTimeUp = 0;
var pointx = 0;
var ismousedown = false;

async function initbasicScene() {
    container = document.getElementById('container');
    renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, logarithmicDepthBuffer: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.outputEncoding = THREE.sRGBEncoding;	//采用sRGBEncoding

    textureUtils = new TextureUtils(renderer);

    let envMapTexture = await textureUtils.GenerateEnvironmentMap(new EnvironmentScene(), 'legacy');

    //envMap = new THREE.PMREMGenerator(renderer).fromCubemap(envMapTexture);
    envMap = envMapTexture;
    //envMap = new TextureLoader().load("../models/hdr/neutral.jpeg"); 

    container.appendChild(renderer.domElement);

    camera = new THREE.PerspectiveCamera(65, window.innerWidth / window.innerHeight, 0.1, 20000);
    cameradistance = setting.cameradistance;
    cameraheight = setting.cameraheight;
    camera.isNear = false;
    controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.2;
    controls.enablePan = false;
    controls.rotateSpeed = new THREE.Vector2(0.75, 0.5);
    controls.enableZoom = false;
    controls.maxPolarAngle = Math.PI * setting.maxPolarAngle;
    controls.minPolarAngle = Math.PI * setting.minPolarAngle;
    controls.minAzimuthAngle = - Infinity;
    controls.maxAzimuthAngle = Infinity;
    controls.enabled = false;
    controls.target.set(setting.playerStartPos.x, setting.playerStartPos.y + cameraheight, setting.playerStartPos.z);
    console.log(cameradistance)
    camera.position.set(setting.playerStartPos.x, setting.playerStartPos.y + cameraheight, setting.playerStartPos.z + cameradistance);
    camera.lookAt(controls.target);


    gltfloader = new GLTFLoader(manager);
    //plyloader = new THREE.PLYLoader(manager);
    //gltfloader.setKTX2Loader(new THREE.KTXLoader(manager))
    // gltfloader.setMeshoptDecoder(new THREE.KTXLoader(manager))
}


function createPoster() {
    $("#loading").show();
    $(".toimg").show();
    $("#poster-container").show();
    var dom = $('.poster-container');
    var width = $('.toimg').width(); //获取dom 宽度
    var height = $('.toimg').height(); //获取dom 高度
    var canvas = document.createElement("canvas"); //创建一个canvas节点
    var scale = 1; //定义任意放大倍数 支持小数
    canvas.width = width * scale; //定义canvas 宽度 * 缩放
    canvas.height = height * scale; //定义canvas高度 *缩放
    //当前滚动条的位置
    var scrollY = $(document).scrollTop();
    var scrollX = $(document).scrollLeft();

    html2canvas(dom[0], {
        useCORS: true, //跨域
        allowTaint: false,
        canvas: canvas, //自定义 canvas
        logging: false,
        letterRendering: false,
        taintTest: false, //在渲染前测试图片
        dpi: window.devicePixelRatio, // window.devicePixelRatio是设备像素比
        width: width,
        height: height,
        scale: scale, // 添加的scale 参数
        scrollY: -scrollY, scrollX: -scrollX
    }).then(function (canvas) {
        var imgUrl = canvas.toDataURL('image/jpeg');
        document.getElementById('posterImg').setAttribute('src', imgUrl);
        $("#poster-container").hide();
        $(".toimg, #posterImg").addClass("imgcenter");
        $("#loading").hide();
    })
}

function getCenterPoint(mesh) {
    var middle = new THREE.Vector3();
    var geometry = mesh.geometry;
    geometry.computeBoundingBox();
    middle.x = (geometry.boundingBox.max.x + geometry.boundingBox.min.x) / 2;
    middle.y = (geometry.boundingBox.max.y + geometry.boundingBox.min.y) / 2;
    middle.z = (geometry.boundingBox.max.z + geometry.boundingBox.min.z) / 2;
    return middle;
}


function cameraFollow(movedir) {
    controls.target.set(moveObj.position.x, moveObj.position.y + cameraheight, moveObj.position.z);
    camera.position.copy(moveObj.position.clone().add(movedir.clone().negate().normalize().multiplyScalar(cameradistance)))
    camera.position.y = moveObj.position.y + cameraheight;
    camera.lookAt(controls.target)
    controls.update();
}

function cameraBelowFllow() {
    var dir = new THREE.Vector3();
    player.getWorldDirection(dir);
    cameraFollow(dir.normalize().negate());
}

function cameraBeforeFllow() {
    var dir = new THREE.Vector3();
    player.getWorldDirection(dir);
    cameraFollow(dir.normalize());
}

function gotoObject(pic, aniTime, callback) {
    if (pic == undefined || pic == null) return;
    var dir = new THREE.Vector3();
    pic.getWorldDirection(dir)

    var newpos = new THREE.Vector3();
    newpos.copy(pic.position);
    newpos.add(dir.clone().normalize().multiplyScalar(400))
    if (!isPrivateSpace)
        newpos.y = setting.floors["floor_" + pic.floorId].startPos.y;
    else
        newpos.y = moveObj.position.y

    var campos = new THREE.Vector3();
    campos.copy(pic.position);
    campos.add(dir.clone().normalize().multiplyScalar(400 + cameradistance));
    campos.y = pic.position.y;

    if (!isPrivateSpace)
        updateFloor(pic.floorId);

    player.visible = false;
  
    var time = 600;
    if (aniTime)
        time = aniTime;

    var chainA = new TWEEN.Tween({
        // x: moveObj.position.x,
        // y: moveObj.position.y,
        // z: moveObj.position.z,
        camx: camera.position.x,
        camy: camera.position.y,
        camz: camera.position.z,
        cx: controls.target.x,
        cy: controls.target.y,
        cz: controls.target.z
    }).to({
        // x: newpos.x,
        // y: newpos.y,
        // z: newpos.z,
        camx: campos.x,
        camy: campos.y,
        camz: campos.z,
        cx: pic.position.x,
        cy: pic.position.y,
        cz: pic.position.z
    }, time).onUpdate(function (res) {

        controls.target.set(res.cx, res.cy, res.cz);
        camera.position.set(res.camx, res.camy, res.camz);
        camera.lookAt(controls.target)
        //cameraBeforeFllow();
    }).onStart(function () {
        executeCrossFade(currentBaseAction, stand, 0.1);
    }).onComplete(function () {
        // cameraBeforeFllow();
        controls.target.set(pic.position.x, pic.position.y, pic.position.z);
        camera.position.set(campos.x, campos.y, campos.z);
        camera.lookAt(controls.target);
        controls.update();
        //   moveObj.rotation.copy(pic.rotation)
        moveObj.position.set(newpos.x, newpos.y, newpos.z);


        player.visible = true;
        player.rotation.copy(new THREE.Euler().setFromQuaternion(pic.quaternion, "YXZ"));

        if (typeof callback === "function") {
            //player.rotation.y += Math.PI / 2
            callback(pic);
        } else {

             var multi = pic.rotation.y * pic.rotation.x > 0 ? -1 : 1;
            player.position.x = multi * 60;
        }
    }).easing(TWEEN.Easing.Quadratic.In)

    return chainA;

}

function resetControl(aniTime) {
    camera.isNear = false;

    new TWEEN.Tween({
        x: controls.target.x,
        z: controls.target.z,
        y: controls.target.y,
        px: player.position.x
    }).to({
        x: moveObj.position.x,
        z: moveObj.position.z,
        y: moveObj.position.y + cameraheight,
        px: 0
    }, 400).onStart(function () {
        executeCrossFade(currentBaseAction, walk, 0.1)
    }).onUpdate(function (res) {
        controls.target.set(res.x, res.y, res.z);
        controls.update();
        camera.lookAt(controls.target)
        player.position.x = res.px;
    }).onComplete(function () {
        controls.enabled = true;

        // player.rotation.x = 0;
        player.position.x = 0;
        // player.rotation.z = 0;
        executeCrossFade(currentBaseAction, stand, 0.1)
    }).start();
}

function activateAction(action) {
    const settings = baseActions[action.actionId];
    setWeight(action, settings.weight);
    action.play();
}

function setWeight(action, weight) {
    action.enabled = true;
    action.setEffectiveTimeScale(1);
    action.setEffectiveWeight(weight);
}

function executeCrossFade(startAction, endAction, duration) {

    if (startAction && endAction && startAction.actionId == endAction.actionId) return;

    if (endAction) {
        setWeight(endAction, 1);
        endAction.time = 0;
        currentBaseAction = endAction;

        if (startAction) {

            // Crossfade with warping

            startAction.crossFadeTo(endAction, duration, true);

        } else {

            // Fade in

            endAction.fadeIn(duration);

        }

    } else {

        // Fade out

        startAction.fadeOut(duration);

        currentBaseAction = null
    }

}

function goToFar(newDestPoint, movedir) {
    //console.log(" go to far ")
    TWEEN.removeAll();

    var destangle = getDestAngle(newDestPoint, moveObj);
    player.rotation.y = destangle;
    let isCrashed = false;
    console.log("newDestPoint.x", newDestPoint)
    var GotoFar = new TWEEN.Tween({ x: moveObj.position.x, z: moveObj.position.z, step: 0 })
        .to({ x: newDestPoint.x, z: newDestPoint.z, step: 1 }, 300)
        .onStart(function () {
            executeCrossFade(currentBaseAction, stand, 0.2)
            player.visible = false;
            controls.enabled = false;
        }).onUpdate(function (res) {
            console.log("onUpdate")
            console.log(res.x, res.z)
            moveObj.position.x = res.x;
            moveObj.position.z = res.z;
            cameraFollow(movedir);
            // if (!isCrashed) {
            //     var result = isCrash(moveObj, movedir);
            //     console.log(result)
            //     if (result != "") {
            //         isCrashed = true;
            //     }
            // } else {
            //     TWEEN.removeAll();
            //     player.visible = true;
            //     controls.enabled = true;
            //     moveObj.position.y = currentfloor.startPos.y
            //     executeCrossFade(currentBaseAction, stand, 0.2)
            // }

        }).onComplete(function () {
            player.visible = true;
            controls.enabled = true;
            moveObj.position.y = currentfloor.startPos.y
            executeCrossFade(currentBaseAction, stand, 0.2)
        });
    console.log(GotoFar)
    GotoFar.start();
}


function setNewPointCirle(newP) {
    if (!circle.visible) circle.visible = true;
    circle.position.x = newP.x;
    circle.position.z = newP.z;
    circle.position.y = currentfloor.startPos.y + 3

    var circleTween = new TWEEN.Tween({ opacity: 1 }).to({ opacity: 0 }, 800).onUpdate(function (res) {
        circle.material.opacity = res.opacity;
    })
    circleTween.start();
}


var piccontainer, piccamera, picscene, picrenderer;

function initplayer() {
    // var cubeMaterial = new THREE.MeshBasicMaterial({ color: 0xab4053, opacity: 0, alphaTest: 1, transparent: true });
    const cubeGeometry = new THREE.BoxBufferGeometry(90, 33, 66);
    player = new THREE.Mesh(cubeGeometry, wallma);
    var dummy = player.clone();
    dummy.rotateY(Math.PI / 2)
    player.add(dummy);
    moveObj.add(player);
    // if (num == setting.player.length) return;
    var model = setting.player[0];
    // var geometry = new THREE.Object3D();
    //console.log("begin load player " + model.id)
    gltfloader.load(model.url, function (gltf) {
        var object = gltf.scene;
        //  object.children[1].intensity = 0.2;
        object.children[1].visible = false;
        object.scale.set(100, 100, 100)
        object.rotateY(Math.PI);
        if (mixer == null)
            mixer = new THREE.AnimationMixer(object);

        var animations = gltf.animations;
        var numAnimations = animations.length;

        for (var i = 0; i !== numAnimations; ++i) {
            var clip = animations[i];
            var action = mixer.clipAction(clip);
            action.actionId = i;
            if (i == 0) {
                stand = action;
                currentBaseAction = action;
            }
            if (i == 3) walk = action;
            // if (i == 0) walk = action;
            if (i == 1) right = action;
            if (i == 2) left = action;
            // if (i == 3) stand = action;
            if (i == 4) speak = action;
            activateAction(action);
        }
        object.position.set(0, -4, 0)
        player.add(object);
        var shadowMa = new THREE.MeshBasicMaterial({ opacity: 0.45, transparent: true, map: textureLoader.load("/images/shadow.png") });
        var shadow = new THREE.Mesh(new THREE.PlaneGeometry(66, 66), shadowMa);
        shadow.rotateX(-Math.PI / 2)
        player.add(shadow)
        player.visible = true;
        shadow.position.set(0, -4.2, 0)
        player.position.set(0, 0, 0)
    });
}
function measureText(options) {
    var strs = (options.text || "").split("\n");
    var font_size = options.fontSize || 14;
  
    let ctx = options.context;
   
    var line_height = 1.5;
    const font = `${options.fontBold || ""} ${font_size}px  ${options.fontFamily || ""}`;
    ctx.font = font;
    

    

    var lines = [];

    ctx.fillStyle = options.fillStyle || "#fff";
    ctx.font = font;
    ctx.textBaseline = "top";
    ctx.textAlign = options.textAlign || "center";

    strs.forEach(str => {
        var start = 0, l = 1;
        while (start < str.length && str) {
            var _str = str.substr(start, l);
            const metrics = ctx.measureText(_str)

            if (metrics.width > options.width) {
                //超出指定长度
                var currentBit = str.substr(start + l - 1, 1);

                if (/[^\u4e00-\u9fa5a-zA-Z\d]+/.test(currentBit)) {
                    //如果是标点;
                    l -= 2;
                } else {
                    l--;
                }
                lines.push(str.substr(start, l));
                start += l;
                l = 1;

            } else if (start + l >= str.length) {
                //最后一行
                lines.push(str.substr(start, l));
                start += l;
            } else {
                l++;
            }
        }
    })
    return {
        textAlign: ctx.textAlign,
        textBaseline: ctx.textBaseline,
        left: 0,
        top: 0,
        ...options,
        font,

        textArr: lines,
        lineHeight: line_height,
        height: lines.length * font_size * line_height
    }
}
function drawText(options) {
    const measureTextResult = options.textArr ? options : measureText(options);
    const ctx = measureTextResult.context;
    ctx.textBaseline = measureTextResult.textBaseline;
    ctx.textAlign = measureTextResult.textAlign;
    ctx.font = measureTextResult.font;
    ctx.fillStyle = measureTextResult.fillStyle;
    let left = measureTextResult.left;
    if (measureTextResult.textAlign == 'center') {
        left += measureTextResult.width / 2;
    } else if (measureTextResult.textAlign == 'right') {
        left += measureTextResult.width;
    }
    const defLen = measureTextResult.textArr.length;
    for (var i = 0, len = Math.min(2, defLen); i < len; i++) {
        var text = measureTextResult.textArr[i];
        if (i == 1 && defLen > 2) {
            text = text.substr(0, text.length - 1) + "...";
        }
        var haftHeight = 0; //(((measureTextResult as any).lineHeight - 1.1) / 2 * measureTextResult.fontSize);
        var top = measureTextResult.top + parseInt(i) * measureTextResult.fontSize * measureTextResult.lineHeight + haftHeight;

        ctx.fillText(text, left, top);
    }
    return measureTextResult;
}

function makeLabelCanvas(size, name) {
    const borderSize = 2;
    let canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const fontFamily = `PingFangSC, "Myriad Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif`;


    let width = 500;

    let measureResult = measureText({ text: name, context: ctx, fontSize: size, fillStyle: "#ffffff", fontFamily, width: width, fontBold: "bold" })
    // measure how long the name will be
    // const doubleBorderSize = borderSize * 2;
    // const width = ctx.measureText(name).width + doubleBorderSize;
    // const height = size + doubleBorderSize;
    canvas.width = measureResult.width;
    canvas.height = measureResult.height;


    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.shadowOffsetX = 2;     //在X轴偏移值
    ctx.shadowOffsetY = 2;    //在Y轴偏移值
    ctx.shadowBlur = 2;       //阴影模糊程度
    ctx.shadowColor = '#787878';  //必填

    //ctx.fillStyle = '#ffffff';

    drawText(measureResult)
    return canvas;
}

var labelGeometry = new THREE.PlaneGeometry(1, 1);
function makeTips(name) {
    var canvas = makeLabelCanvas(26, name);

    var texture = new THREE.CanvasTexture(canvas);
    texture.minFilter = THREE.LinearFilter;
    texture.wrapS = texture.wrapT = THREE.ClampToEdgeWrapping;

    //let url = canvas.toDataURL('image/png');
    var labelMaterial = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.DoubleSide,
        opacity: 1,
        transparent: true
    });
    const label = new THREE.Mesh(labelGeometry, labelMaterial);
    const labelBaseScale = 0.5;
    label.scale.x = canvas.width * labelBaseScale;
    label.scale.y = canvas.height * labelBaseScale;

    return label;
}

function freeNode(node) {
    node.visible = false
    node.material.dispose();
    node.geometry.dispose();
    scene.remove(node)
}

function PrivatefreeNode(node) {
    node.visible = false
    node.material.dispose();
    node.geometry.dispose();
    privateScene.remove(node)
}

function getDestAngle(newP, player) {

    let target_vector = new THREE.Vector3(newP.x - moveObj.position.x, 0, newP.z - moveObj.position.z);

    let qu_diff = new THREE.Quaternion().setFromUnitVectors(new THREE.Vector3(0, 0, -1), target_vector.normalize());
    const y = new THREE.Euler().setFromQuaternion(qu_diff, "YXZ").y;
    const y_min = (y - Math.PI * 2) % (Math.PI * 2)
    const y_max = (y + Math.PI * 2) % (Math.PI * 2)
    return Math.abs(y_min - player.rotation.y) < Math.abs(y_max - player.rotation.y) ? y_min : y_max;


    var x2 = Math.round(newP.x),
        y2 = -Math.round(newP.z),
        x1 = Math.round(moveObj.position.x),
        y1 = -Math.round(moveObj.position.z)
    var newPieAng = Math.atan2(y2 - y1, x2 - x1)
    // console.log(newPieAng)
    angle = getMinAngle(newPieAng, player.rotation.y);
    var destangle = player.rotation.y + angle

    return destangle;
}

//点所在的rotaion.y
function getMinAngle(newPieAng, oldPieAngle) {
    var new2PAng = (newPieAng + 2 * Math.PI) % (2 * Math.PI);
    var newRAng = Math.round(new2PAng * 180 / Math.PI);

    var passAng = (oldPieAngle + 2 * Math.PI) % (2 * Math.PI);
    var passRAng = Math.round(passAng * 180 / Math.PI);
    var currentRAng = (passRAng + 90 + 360) % 360;
    var deltha = Math.round(newRAng - currentRAng);
    // console.log("当前位置"+ currentRAng+",新"+ newRAng+" 差"+deltha);
    if (Math.abs(deltha) > 180) {
        if (deltha < 0) {
            deltha = 360 + deltha
        } else {
            deltha = -(360 - deltha)
        }
    }
    return deltha / 180 * Math.PI;
}

function isCrash(moveObj, movedir) {
    //到达二、三楼楼梯口
    //if (skipCrashCheckLouti) return false;

    var oriPoint = moveObj.position.clone();

    // if (currentfloor.floorId != 1) {
    //   var downVector = new THREE.Vector3(0, -1, 0);
    //   var vector = moveObj.position.clone().normalize();
    //   // player.getWorldDirection( vector );
    //   var rcaster = new THREE.Raycaster();
    //   rcaster.set(oriPoint, downVector);
    //   var isIntersected = rcaster.intersectObject(currentfloor);
    //   if (isIntersected.length == 0) {
    //     crashBack(vector.negate(), 30);
    //     return true;
    //   }
    // }
    // var movedir = new THREE.Vector3();
    // player.getWorldDirection(movedir)
    if (checkCrash(oriPoint, movedir, "front"))
        return "front"

    // var dummyvector = new THREE.Vector3();
    // var dummy = player.children[0];
    // dummy.getWorldDirection(dummyvector);

    // if (checkCrash(oriPoint, dummyvector, "right"))
    //     return "right";

    // var right = dummyvector.negate().clone();
    // if (checkCrash(oriPoint, right, "left"))
    //     return "left";

    return "";
}

function crashBack(crashdir, backstep) {
    // action.paused = true;
    //  console.log("crashed on moving")
    // var mdir = new THREE.Vector3();
    // player.getWorldDirection(mdir);

    var dummy = crashdir.clone();
    dummy.y = 0
    var addpos = dummy.clone().negate().normalize().setLength(backstep);
    // console.log( "add  "+ addpos.x+" "+addpos.y+" "+addpos.z)

    moveObj.position.add(addpos);
}

function hanleUpDownStairs(newP) {
    // console.log(" auto up down stair")
    var isUp = true;
    //isOnStair = true;
    if (newP.y > currentfloor.startPos.y) {
        isUp = true;
    } else {
        isUp = false;
    }
    if (currentfloor.floorId == 3) isUp = false;

    var currentpath = null;
    var nextfloorId = 0;
    //每次上或下一层
    if (currentfloor.floorId == "G") {
        currentpath = setting.movepath[0];
        nextfloorId = 2;
    }
    else if (currentfloor.floorId == 2) {
        if (isUp) {
            currentpath = setting.movepath[1];
            nextfloorId = 3
        }
        else {
            currentpath = setting.movepath[0];
            nextfloorId = "G"
        }
    }
    else {
        //3
        if (!isUp) {
            currentpath = setting.movepath[1];
            nextfloorId = 2
        }
    }

    if (currentpath == null) return;

    var start_i = isUp ? 1 : currentpath.length - 1;
    var end_i = isUp ? currentpath.length - 1 : 0;

    autoMove(currentpath, start_i, end_i, 1000, function () {
        updateFloor(nextfloorId)
    })
}

function handlePlayerMove(event) {

    exitSearchView();
    if (controls.enabled == false) return;
    if ((dragTimeUp - dragTimeDown) > 200) {
        return;
    }
    event.preventDefault();
    mouse.x = (event.clientX / (window.innerWidth/2)) - 1;
    mouse.y = - (event.clientY /( window.innerHeight/2)) + 1;

    raycaster.setFromCamera(mouse, camera);

    var intersects = raycaster.intersectObjects(objlist);
    isCrashed = false;

    if (!player.visible) player.visible = true;
    if (intersects.length > 0) {

        var obj = intersects[0];
        var clickname = obj.object.name;
        var newP = obj.point;
        //只能点击当前层地板及楼梯
        //透明墙是1， 画是2
        if (clickname.startsWith(setting.Sys_picture_start_name) && obj.object.floorId == currentfloor.floorId) {
            showPic(obj, true)
            if (!isshowPic)
                moveAnimateByPoint(newP)
            return;
        }

        var picObj = null;
        if (intersects.length > 1 && clickname.toLowerCase().indexOf('neiqiang') > -1) {
            picObj = intersects[1]
            if (picObj.object.name.startsWith(setting.Sys_picture_start_name) && picObj.object.floorId == currentfloor.floorId) {
                showPic(picObj, true)
                if (!isshowPic)
                    moveAnimateByPoint(newP)
                return;
            }
        }
        console.log(clickname)
        //点击视频
        if (clickname == "video") {
            if (!video.paused)
                video.pause();
            else {
                right_video && right_video.pause();
                if (!video.isloaded) {
                    video.load();
                }
                video.play();
            }
            return
        } else if (clickname == "rightvideo") {
            if (!right_video.paused)
                right_video.pause();
            else {
                video && video.pause();
                if (!right_video.isloaded) {
                    right_video.load();
                }
                right_video.play();
            }
            return
        }

        //门
        if (clickname == "dummydoor") {
            if (door.isOpen) {
                enterIntoPrivate(sid);
                return;
            }
            if (isInvite) {
                opendoor(0)
            } else {
                doorlogo.forEach(function (element) {
                    element.visible = false;
                });
                if (!isInvite) {

                    layergroup.visible = true;
                    if (isloadscene) {
                        checkIsAuth();
                        return;
                    }
                    isloadscene = true;
                    // console.log(" get scene list ")
                    getPrivateSceneList(function (data) {
                        if (data["0"]) {
                            labelRenderer = new CSS3DRenderer();
                            labelRenderer.setSize(window.innerWidth, window.innerHeight);
                            labelRenderer.domElement.style.position = 'absolute';
                            labelRenderer.domElement.style.top = '0';
                            labelRenderer.domElement.style.pointerEvents = 'none';
                            container.appendChild(labelRenderer.domElement);

                            var scenelist = initScenelistlabel(data);
                            var label = new CSS3DObject(scenelist);
                            label.position.y = 220;
                            label.position.x = 110;
                            door.add(label);
                            labelRenderer.render(scene, camera);
                        }
                        var timeoutID = setTimeout(function () {
                            checkIsAuth();
                            initScenelistevent();
                            clearTimeout(timeoutID)
                        }, 300);
                    })
                }
            }
            return;
        }

        //上下楼动画
        if (clickname == setting.laddersname.name) {
            if (obj.distance > 1500) return;
            hanleUpDownStairs(newP)
            return;
        } else if (currentfloor.floorId != "G") {
            //二、三楼不能走中间
            var centerP = new THREE.Vector3(0, currentfloor.startPos.y, -600);
            newP.y = moveObj.position.y
            var banDis = centerP.distanceTo(newP);

            if (banDis < 1460) {
                moveAnimateByPoint(newP);
                return;
            }
        }
        //开始走其它地言，只允许点地面
        // if (clickname != currentfloor.name)
        //   return;
        // executeCrossFade(currentBaseAction, walk, 0.1)
        moveAndFar(newP)
    }
}


function autoMove(currentpath, start_i, end_i, time, callback) {
    TWEEN.removeAll();
    var index = -1;
    new TWEEN.Tween({ i: start_i })
        .to({ i: end_i }, time)
        .onStart(function () {
            executeCrossFade(currentBaseAction, walk, 0.1);
            controls.enabled = false;
        })
        .onUpdate(function (res) {
            var next = Math.floor(res.i);
            if (next != index) {
                var point = currentpath[next];

                var step = new THREE.Vector3(point[0], point[1], point[2]);
                var destangle = getDestAngle(step, player)
                player.rotation.y = destangle;

                moveObj.position.x = step.x
                moveObj.position.y = step.y
                moveObj.position.z = step.z

                index = next;
                controls.target.set(moveObj.position.x, moveObj.position.y + cameraheight, moveObj.position.z)
                camera.position.set(moveObj.position.x, moveObj.position.y + cameraheight, moveObj.position.z + cameradistance)
                camera.lookAt(controls.target)
                //cameraBelowFllow();
                controls.update();
            }

        }).onComplete(function () {
            executeCrossFade(currentBaseAction, stand, 0.1);
            // moveObj.lookAt(new THREE.Vector3(0, 0, 1));
            controls.enabled = true;
            if (callback) callback();
            // updateFloor(nextfloorId);
        }).easing(TWEEN.Easing.Quadratic.InOut)
        .start();
}

function checkCrash(oriPoint, dir, num) {
    var rcaster = new THREE.Raycaster();
    var ajusrDir = dir.clone();
    var checklist = isPrivateSpace ? privateObjlist : objlist;
    rcaster.set(oriPoint, ajusrDir.clone().normalize());
    var collistionResults = rcaster.intersectObjects(checklist);

    if (collistionResults.length > 0) {
        let dist = setting.cameradistance;
        if (collistionResults[0].object.name.indexOf("wall") >= 0) {
            dist = setting.cameradistance - 170;
        }
        if (collistionResults[0].distance <= (dist-30)) {
            //console.log(num, collistionResults[0])

            return true;
        }
    }
    return false;
}


function onMouseDown(event) {
    ismousedown = true;
    dragTimeDown = new Date().getTime();
}

function onMouseUp(event) {
    ismousedown = false;
    dragTimeUp = new Date().getTime();
    onMouseClick(event);
}

function onMouseClick(event) {
    if (isPrivateSpace)
        handlePlayerMoveIn3S(event)
    else
        handlePlayerMove(event);
}

function handlePlayerMoveIn3S(event) {
    exitSearchView();

    if (controls.enabled == false) return;
    if ((dragTimeUp - dragTimeDown) > 200) {
        return;
    }
    event.preventDefault();

    mouse.x = (event.clientX / (window.innerWidth / 2)) - 1;
    mouse.y = - (event.clientY / (window.innerHeight / 2)) + 1;
    //    pointx = event.clientX -  window.innerWidth/2;
    raycaster.setFromCamera(mouse, camera);

    var intersects = raycaster.intersectObjects(privateObjlist);
    isCrashed = false;
    if (!player.visible) player.visible = true;
    if (intersects.length > 0) {
        var obj = intersects[0];
        var clickname = obj.object.name;
        var newP = obj.point;
        //只能点击当前层地板及楼梯
        //透明墙是1， 画是2
        if (clickname.startsWith(setting.Sys_picture_start_name)) {
            showPic(obj, false)
            if (!isshowPic)
                moveAnimateByPoint(newP)
            return;
        }
        var picObj = null;
        if (intersects.length > 1 && clickname.toLowerCase().indexOf('neiqiang') > -1) {
            picObj = intersects[1]
            if (picObj.object.name.startsWith(setting.Sys_picture_start_name)) {
                showPic(picObj, false)
                if (!isshowPic)
                    moveAnimateByPoint(newP)
                return;
            }
        }

        if ((clickname == "space" || clickname == "dummydoor") && door) {
            exitPrivate();
            return;
        }

        if (clickname == "video") {
            if (!pVideoLeft.paused)
                pVideoLeft.pause();
            else {
                if (pVideoRight)
                    pVideoRight.pause();

                if (!pVideoLeft.isloaded) {
                    pVideoLeft.load();
                }
                pVideoLeft.play();
            }
            return
        } else if (clickname == "rightvideo") {
            if (!pVideoRight.paused)
                pVideoRight.pause();
            else {
                if (pVideoLeft)
                    pVideoLeft.pause();
                if (!pVideoRight.isloaded) {
                    pVideoRight.load();
                }
                pVideoRight.play();
            }
            return
        }

        
        moveAndFar(newP);
    }
}
function dispose() {

    scene && scene.dispose();
    privateScene && privateScene.dispose();
}
function initCustomModels(curscene) {
    return new Promise((resolve) => {
        var scenetype = curscene.scenetype.toLowerCase();
        if (curscene.enable) {
            // console.log("begin load " + curscene.name)
            var rootPath = curscene.root;
            var url = curscene.url || (rootPath + "/" + curscene.name);
            var isPublic = curscene.isPublic || false;
            var needLight = curscene.needLight || false;

            switch (scenetype) {
                case "fbx":
                    loader.load(url, function (object) {
                        addCustomModel(object, curscene, isPublic, needLight);
                        resolve(object)
                    });
                    break;
                case "glb":
                    var url = curscene.url || (rootPath + "/" + curscene.name);
                    gltfloader.load(url, function (glb) {
                        var object = glb.scene;
                        addCustomModel(object, curscene, isPublic, needLight);
                        resolve(glb)
                    });
                    break;
            }
            // console.log("end of load " + curscene.name)
        }
    })
}

function addCustomModel(object, curscene, isPublic, needLight) {
    object.scale.set(curscene.scale, curscene.scale, curscene.scale);
    var box = checkbox.setFromObject(object);
    var size = new THREE.Vector3()
    box.getSize(size);
    var mesh = new THREE.Mesh(new THREE.BoxBufferGeometry(size.x, size.y, size.z), wallma);
    if (curscene.ajustHeight != undefined && curscene.ajustHeight) {
        mesh.position.set(curscene.pos.x, curscene.pos.y + size.y / 2, curscene.pos.z);
    } else {
        mesh.position.set(curscene.pos.x, curscene.pos.y, curscene.pos.z);
    }
    mesh.add(object);
    if (needLight) {
        object.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.material = new THREE.MeshStandardMaterial({ envMap: envMap, side: DoubleSide, color: new THREE.Color("rgb(0,0,0)"), envMapIntensity: 0.1, roughness: 0.5, metalness: 0.5, flatShading: true })
            }
        })
    } else {
        object.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.material.metalness = 0;
            }
        })
    }


    object.lookAt(new THREE.Vector3(0, mesh.position.y, 0));

    // object.traverse(function (obj) {
    //   setDoubleSide(obj)
    // });


    if (isPublic) {
        publicModelGroup.add(mesh);
    } else {
        privateObjlist.push(mesh);
        // privateCrashlist.push(mesh);
        customModelGroup.add(mesh);
    }
}


//move and angle
function getRotatetween(newP) {
    var destangle = getDestAngle(newP, player)
    var rotatetween = null;
    if (player.rotation.y != destangle) {
        var rtime = Math.abs(destangle - player.rotation.y) * 400
        rotatetween = new TWEEN.Tween({ d: player.rotation.y, x: player.rotation.x, z: player.rotation.z }).to({ d: destangle, x: 0, z: 0 }, rtime)
            .onStart(function () {
                walk.setEffectiveWeight(1.5);
                executeCrossFade(currentBaseAction, walk, 0.2)
            })
            .onUpdate(function (res) {
                player.rotation.y = res.d;
                // player.rotation.x= this.x;
                // player.rotation.z = this.z;
            })
            .easing(TWEEN.Easing.Quadratic.In)
    }
    return rotatetween;
}

function getMoveTween(movedir, distance, callback) {
    isCrashed = false;
    var movetween = null;
    var totalstep = Math.ceil(distance / steplength);
    var time = setting.moveTimeScale * distance;
    let lastStep = 0;
    movetween = new TWEEN.Tween({
        step: lastStep
    }).to({
        step: distance
    },
        time
    ).onStart(function () {
        walk.setEffectiveWeight(1);
        walk.setEffectiveTimeScale(1);
        executeCrossFade(currentBaseAction, walk, 0.3)
    }).onUpdate(function (res,b) {
    
        if (!isCrashed) {
            var result = isCrash(moveObj, movedir.clone())
            if (result != "") {
                isCrashed = true;
            } else {
                console.log(res.step - lastStep)
                moveObj.position.add(movedir.clone().setLength(res.step - lastStep))
                controls.target.set(moveObj.position.x, moveObj.position.y + cameraheight, moveObj.position.z);
                controls.update();
                camera.position.add(movedir.clone().setLength(res.step-lastStep))
                camera.lookAt(controls.target)
            }
        } else {

            TWEEN.removeAll();
            executeCrossFade(currentBaseAction, stand, 0.1);
            controls.target.set(moveObj.position.x, moveObj.position.y + cameraheight, moveObj.position.z);
            controls.update();

            var campos = camera.position.clone();
            campos.y = moveObj.position.y;

            var cameradir = campos.sub(moveObj.position.clone());
            var camnewPos = moveObj.position.clone().add(cameradir.clone().normalize().setLength(cameradistance))
            camera.position.set(camnewPos.x, camnewPos.y + cameraheight, camnewPos.z)
            camera.lookAt(controls.target)

            circle.material.opacity = 0;
            if (door.isOpen && !isPrivateSpace) {
                door.rotation.y = -Math.PI / 2;
                privateenter.visible = true;
                banprivate.visible = false;
                layergroup.visible = true;
            }
            callback && callback()
        }
        lastStep = res.step;
        isNearPrivateSpace();
    }).onComplete(function () {
        executeCrossFade(currentBaseAction, stand, 0.42);
        console.log("onComplete", callback)
        callback && callback();
    }).easing(TWEEN.Easing.Linear.None);
    return movetween;
}

function moveAnimate(newP, movedir, distance) {
    TWEEN.removeAll();
    setNewPointCirle(newP);
    var rotatetween = getRotatetween(newP);
    var movetween = getMoveTween(movedir, distance)
    if (rotatetween && movetween) {
        rotatetween.chain(movetween).start();
    } else if (rotatetween) {
        rotatetween.onComplete(function () {
            executeCrossFade(stand, 0.1)
        }).start();
    }
    else if (movetween)
        movetween.start();
    else
        executeCrossFade(stand, 0.1)
}

function moveAnimateSync(newP, movedir, distance) {
    return new Promise((resolve) => {
        TWEEN.removeAll();
        setNewPointCirle(newP);
        var rotatetween = getRotatetween(newP);
        var movetween = getMoveTween(movedir, distance, resolve)

        if (rotatetween && movetween) {
            rotatetween.chain(movetween).start();
        } else if (rotatetween) {
            rotatetween.onComplete(function () {
                executeCrossFade(stand, 0.1)
                resolve()
            }).start();
        }
        else if (movetween)
            movetween.start();
        else { executeCrossFade(stand, 0.1); resolve() }

    })
}

function moveAnimateByPoint(newP) {
    var movep = newP.clone();
    movep.y = moveObj.position.y;
    var ori = moveObj.position.clone();
    var movedir = movep.sub(ori.clone());
    var distance = moveObj.position.distanceTo(newP)
    moveAnimate(newP, movedir, distance)
}
function getCorners(box3, y) {
    return [
        new THREE.Vector3(box3.min.x, y, box3.min.z), // 000
        new THREE.Vector3(box3.min.x, y, box3.max.z), // 001
        new THREE.Vector3(box3.max.x, y, box3.min.z), // 100
        new THREE.Vector3(box3.max.x, y, box3.max.z), // 101
    ];
}
async function moveAndFar(newP) {
    //TWEEN.removeAll();
    var distance = moveObj.position.distanceTo(newP)

    if (distance < setting.playerCrashDistance) return;
    newP.y = moveObj.position.y;
    var movep = newP.clone();
    var ori = moveObj.position.clone();

    var movedir = movep.sub(ori);
    movedir.normalize();

    var movercaster = new THREE.Raycaster(moveObj.position.clone(), movedir.clone(), 10, distance);
    var checklist = isPrivateSpace ? privateObjlist : objlist;
    var collis = movercaster.intersectObjects(checklist);
    if (collis.length > 0 ) {
        let dist = setting.cameradistance;
         if (collis[0].object.name.indexOf("wall") >= 0) {
            dist = setting.cameradistance - 170;
        }
        newP = collis[0].point.clone().add(movedir.clone().negate().setLength(Math.min(dist - 30, collis[0].distance)));
        newP.y = moveObj.position.y;
        distance = moveObj.position.distanceTo(newP);

    } else {
        newP.add(movedir.clone().negate().setLength(30));
    }
   
    if (distance > setting.playerMaxdistance - 333) {

        goToFar(newP, movedir);
    } else if(Math.floor(distance)>0){

        moveAnimate(newP, movedir, distance)


    }





    // if (collis.length > 0) {

    //     let geos = [collis[0].object.geometry.clone()]//collis.map((item) => item.object.geometry.clone());
    //     const mergeGeo = BufferGeometryUtils.mergeBufferGeometries(geos, true)
    //     mergeGeo.scale(1.2,1,1.2)
    //     const box3x = new THREE.Box3();
    //     mergeGeo.computeBoundingBox()
    //     box3x.copy(mergeGeo.boundingBox)
    //     console.log(moveObj.position)
    //     let corners = getCorners(box3x, moveObj.position.y);
    //     corners = corners.map(item => {
    //         return { value: item.distanceTo(moveObj.position), point: item }
    //     })
    //     corners.sort((a, b) => a.value - b.value);
    //     console.log(corners)
    //     for (const i in [0,1,2]) {
    //         newP = corners[i].point
    //         var movep = newP.clone();
    //         var ori = moveObj.position.clone();
    //         movep.y = moveObj.position.y;
    //         var movedir = movep.sub(ori.clone());
    //         console.log(i, newP, movep)
    //         await moveAnimateSync(newP, movep, moveObj.position.distanceTo(newP));

    //     }
    //     return;




}

function ready() {

    sid = sid = GetQueryString("sid")
    invitecode = invitecode = GetQueryString("invitecode");
    ismobile = ismobile = is_mobile();
    position = position = GetQueryString("position");

    if (invitecode != undefined && invitecode != null && invitecode != "") {

        isAllowEnterPrivate(sid, invitecode, function (res) {
            if (!res) {
                //无权进入
                isInvite = isInvite = false;
            } else
                isInvite = isInvite = true;
        });
    }
    genturnvideo();
    startinit();
}
function getDataList() {
    return isPrivateSpace ? privateArtList : artList;
}

let defaults = {
    scene,
    getDataList,
    hideshare,
    closeLargePic,
    closeGuidePic,
    setting,
    showLogin,
    privateObjlist,
    manager,
    wallma,
    sid,
    exitPrivate,
    moveObj,
    showSearchView,
    mouse,
    isPrivateSpace,
    currentBaseAction,
    mixer,
    showPic,
    cameraheight,
    baseActions, door,
    isNearPrivateSpace,
    isshowPic,
    position,
    exitSearchView,
    video,
    opendoor,
    isInvite,
    mouse,
    hidePic,
    enterIntoPrivate,
    showPic,
    raycaster,
    objlist,
    privateScene,
    privateenter,
    banprivate,
    exit,
    layergroup,
    dispose,
    checkbox,
    onKeywordKeyDown,
    customModelGroup,
    ready,
    currentfloor,
    event,
    textureLoader,
    right_video, turnvideo, space, spacethumb
}



export default defaults


