const map={
    "2F":{
        "1":"N_2F_2A_1",
        "2":"N_2F_2A_2",
        "3":"N_2F_2A_3",
        "4":"N_2F_2A_4",
        "5":"N_2F_2A_5",
        "6":"N_2F_2A_6",
        "7":"N_2F_2A_7",
        "8":"N_2F_2A_8",

        "9":null,
        
        "10":"N_2F_2A_9",
        "11":"N_2F_2A_10",
        "12":"N_2F_2A_11",
        "13":"N_2F_2A_12",
        "14":"N_2F_2A_13",
        "15":"N_2F_2A_14",
        "16":"N_2F_2A_15",
        "17":"N_2F_2A_16",
        "18":"N_2F_2A_17",
        "19":"N_2F_2A_18",
        "20":"N_2F_2A_19",
        "21":"N_2F_2A_20",
        "22":"N_2F_2A_21",
        "23":"N_2F_2A_22",
        "24":"N_2F_2A_23",
        "25":"N_2F_2A_24",
        "26":"N_2F_2A_25",
        "27":"N_2F_2A_26",
        "28":"N_2F_2A_27",
        "29":"N_2F_2A_28",
        "31":"N_2F_2A_29",
        "32":"N_2F_2A_30",
        "33":"N_2F_2A_31",

        "34":null,

        "35":"N_2F_2A_33",
        "36":"N_2F_2A_34",
        "37":"N_2F_2A_35",
        "38":"N_2F_2A_36",
        "39":"N_2F_2A_37",
        "40":"N_2F_2A_38",
        "41":"N_2F_2A_39",
        "42":"N_2F_2B_1",
        "43":"N_2F_2B_2",
        "44":"N_2F_2B_3",
        "45":"N_2F_2B_4",
        "46":"N_2F_2B_5",
        "47":"N_2F_2B_6",

        "49":"N_2F_2B_7",
        "50":"N_2F_2B_8",
        "51":"N_2F_2B_9",
        "52":"N_2F_2B_10",
        "53":"N_2F_2B_11",
        "54":"N_2F_2B_12",
        "55":"N_2F_2B_13",
        "56":"N_2F_2B_14",
        "57":"N_2F_2B_15",
        "58":"N_2F_2B_16",
        "59":"N_2F_2B_17",
        "60":"N_2F_2B_18",
        "61":"N_2F_2B_19",
        "62":"N_2F_2B_20",

        "63":null,

        "64":"N_2F_2B_21",
        "65":"N_2F_2B_22",
        "66":"N_2F_2B_23",
        "67":"N_2F_2B_24",
        "68":"N_2F_2B_25",
        "69":"N_2F_2B_26",
        "70":"N_2F_2B_27",
        "71":"N_2F_2B_28",
        "72":"N_2F_2B_29",
        "73":"N_2F_2B_30",
        "74":"N_2F_2B_31",
        "75":"N_2F_2B_32",
        "76":"N_2F_2B_33",
        "77":"N_2F_2B_34",
        "78":"N_2F_2B_35",
        "79":"N_2F_2B_36",
        "80":"N_2F_2B_37",
        "81":"N_2F_2B_38",
        "82":"N_2F_2B_39",
        "83":"N_2F_2B_40",
        "84":"N_2F_2B_41",
        "85":"N_2F_2B_42",
        "86":"N_2F_2B_43",
        "87":"N_2F_2B_44",

        "88":null,
        "89":"N_2F_2B_45",
        "90":"N_2F_2B_46",
        "91":"N_2F_2B_47",
        "92":"N_2F_2C_1",
        "93":"N_2F_2C_2",
        "94":"N_2F_2C_3",
        "95":"N_2F_2C_4",
        "96":"N_2F_2C_5",
        "97":"N_2F_2C_6",
        "98":"N_2F_2C_7",
        "99":"N_2F_2C_8",
        "100":"N_2F_2C_9",
        "101":"N_2F_2C_10",

        "102":null,
        "103":null,
        "104":"N_2F_2C_11",
        "105":"N_2F_2C_12",
        "106":null,
        "107":null,
        "108":null,
        "109":null,
        "110":"N_2F_2C_13",
        "111":"N_2F_2C_14",
        "112":null,
        "113":null,

        "114":"N_2F_2C_15",
        "115":"N_2F_2C_16",
        "116":"N_2F_2C_17",
        "117":"N_2F_2C_18",

        "118":"N_2F_2C_19",
        "119":"N_2F_2C_20",
        "120":"N_2F_2C_21",
        "121":"N_2F_2C_22",
        "122":"N_2F_2C_23",
        "123":"N_2F_2C_24",
        "124":"N_2F_2C_25",
        "125":"N_2F_2C_26",

        "126":"N_2F_2C_27",
        "127":"N_2F_2C_28",
        "128":"N_2F_2C_29",
        "129":"N_2F_2C_30",
        "130":"N_2F_2C_31",
        "131":"N_2F_2C_32",
        "132":"N_2F_2C_33",

        "133":null,
        "134":null,
        "135":null,
        "136":null,
        "137":null,

        "138":"N_2F_2C_34",
        "139":"N_2F_2C_35",
        "140":"N_2F_2C_36",
        "141":null,//海报位置
        "142":"N_2F_2C_37",

        "143":"N_2F_2C_38",
        "144":"N_2F_2C_39",
        "145":"N_2F_HALL_1",
        "146":"N_2F_HALL_2",
        "147":"N_2F_HALL_3",
        "148":"N_2F_HALL_4",
        "149":"N_2F_HALL_5",
        "150":"N_2F_HALL_6",
        "151":"N_2F_HALL_7",
        "152":"N_2F_HALL_8",

        "153":"N_2F_HALL_9",
        "154":"N_2F_HALL_10",
        "155":"N_2F_HALL_11",
        "156":"N_2F_HALL_12",
        "157":"N_2F_HALL_13",
        "158":"N_2F_HALL_14",
        "159":"N_2F_HALL_15",
        "160":"N_2F_HALL_16",
        "161":"N_2F_HALL_17",
        "162":"N_2F_HALL_18",

        "163":"N_2F_HALL_19",
        "164":"N_2F_HALL_20",
        "165":"N_2F_HALL_21",
        "166":"N_2F_HALL_22",
        "167":"N_2F_HALL_23",
        "168":"N_2F_HALL_24",
        "169":"N_2F_HALL_25",
        "170":"N_2F_HALL_26",
        "171":"N_2F_HALL_27",
        "172":"N_2F_HALL_28",

        "173":null,
        "174":"N_2F_HALL_29",
        "175":"N_2F_HALL_30",
        "176":"N_2F_HALL_31",
        "177":"N_2F_HALL_32",
        "178":"N_2F_HALL_33",
        "179":"N_2F_HALL_34",
        "180":"N_2F_HALL_35",
        "181":"N_2F_HALL_36",
        "182":"N_2F_HALL_37",

        "183":"N_2F_HALL_39",
        "184":"N_2F_HALL_40",
        "185":"N_2F_HALL_41",
        "186":"N_2F_HALL_42",
        "187":"N_2F_HALL_43",
        "188":"N_2F_HALL_44",
        "189":"N_2F_HALL_45",
        "190":null,//海报

        "191":"N_2F_HALL_46",
        "192":"N_2F_HALL_47",
        "193":"N_2F_HALL_48",
        "194":"N_2F_HALL_49",
        "195":"N_2F_HALL_50",

       

        "196":"N_2F_HALL_51",
        "197":"N_2F_HALL_52",
        "198":"N_2F_HALL_53",
        "199":"N_2F_HALL_54",
        "200":"N_2F_HALL_55",
        "201":"N_2F_HALL_56",
        "202":"N_2F_HALL_57",
        "203":"N_2F_HALL_58",

        "204":"N_2F_HALL_59",
        "205":"N_2F_HALL_60",
        "206":"N_2F_HALL_61",
        "207":"N_2F_HALL_62",
        "208":"N_2F_HALL_63",
        "209":"N_2F_HALL_64",
        "210":"N_2F_HALL_65",
    }
}
export default map;