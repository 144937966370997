export default {
    "maxPolarAngle": 0.7,
    "minPolarAngle": 0.4,
    "floors": {
        "floor_3": {
            "floorId": 3,
            "name": "F3Dimian",
            "startPos": {
                "x": 1333,
                "y": 1012,
                "z": 120
            }
        },
        "floor_2": {
            "floorId": 2,
            "name": "F2Dimian",
            "startPos": {
                "x": 187,
                "y": 530,
                "z": -2100
            }
        },
        "floor_G": {
            "floorId": "G",
            "name": "F1Dimian",
            "startPos": {
                "x": -1267,
                "y": 5,
                "z": -533
            }
        }
    },
    "floornames": [
        "F1Dimian",
        "F2Dimian",
        "F3Dimian",
        "F3Dimian_Close"
    ],
    "laddersname": {
        "name": "louti",
        "dummyname": "xunilouti"
    },
    "cameradistance": 230,
    "cameraheight": 170,
    "picture_start_name": "P_F",
    "Sys_picture_start_name": "N_",
    "playerMaxdistance": 2500,
    "playerCrashDistance": 50,
    "moveTimeScale": 2,
    "playerStartPos": {
        "x": -1000,
        "y": 5,
        "z": 1066
    },
    "privateStartPos": {
        "x": 1900,
        "y": 1014,
        "z": -600
    },
    "player": [
        {
            "id": 0,
            "url": require("./assets/models/glb/inna.glb"),
        }
    ],
    "scene": [
        {
            "name": "sceneV3.fbx",
            "scenetype": "fbx",
            "root": "./models/fbx",
            "url": require("./assets/models/fbx/sceneV3.fbx"),
            "scale": 1,
            "pos": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "crashnode": "MSG",
            "picnode": "Pictures",
            "type": "main",
            "enable": true
        },
        {
            "name": "PrivateDoor.fbx",
            "scenetype": "fbx",
            "root": "./models/fbx",
            "url": require("./assets/models/fbx/PrivateDoor.fbx"),
            "scale": 0.55,
            "pos": {
                "x": 1800,
                "y":1000,
                "z": -550
            },
            "type": "door",
            "enable": true
        }
    ],
    "privatescene": [
        {
            "name": "privateSpace.fbx",
            "scenetype": "fbx",
            "root": "./models/fbx",
            "url": require("./assets/models/fbx/privateSpace.fbx"),
            "scale": 1,
            "pos": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "crashnode": "MSG",
            "picnode": "P_F3",
            "type": "main",
            "enable": true
        },
        {
            "name": "F3Qiang.fbx",
            "scenetype": "fbx",
            "root": "./models/fbx",
            "url": require("./assets/models/fbx/F3Qiang.fbx"),
            "scale": 1,
            "pos": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "type": "privatewall",
            "enable": true
        }
    ],
    "customModel": [
        {
            "name": "simple_cola_can.glb",
            "scenetype": "glb",
            "root": "./models/custom",
            "url": require("./assets/models/custom/simple_cola_can.glb"),
            "scale": 100,
            "pos": {
                "x": 0,
                "y": 1010,
                "z": 0
            },
            "ajustHeight": true,
            "enable": true
        },
        {
            "name": "Deer.glb",
            "scenetype": "glb",
            "root": "./models/customglb",
            "url": require("./assets/models/custom/Deer.glb"),
            "scale": 100,
            "pos": {
                "x": 2000,
                "y": 1010,
                "z": -1600
            },
            "enable": true
        },
        {
            "name": "Dinosaur.glb",
            "scenetype": "glb",
            "root": "./models/customglb",
            "url": require("./assets/models/custom/Dinosaur.glb"),
            "scale": 100,
            "pos": {
                "x": -500,
                "y": 1010,
                "z": -3000
            },
            "enable": true
        },
        {
            "name": "SittingBox.glb",
            "scenetype": "glb",
            "root": "./models/customglb",
            "url": require("./assets/models/customglb/SittingBox.glb"),
            "scale": 300,
            "pos": {
                "x": 1800,
                "y": 1000,
                "z": 0
            },
          
            "needLight":true,
            "isPublic": true,
            "enable": true
        },
       

        {
            "name": "Duck.glb",
            "scenetype": "glb",
            "root": "./models/custom",
            "url": require("./assets/models/custom/Duck.glb"),
            "scale": 1,
            "pos": {
                "x": -530,
                "y": 1010,
                "z": -3000
            },
            "enable": false
        },
        {
            "name": "Panda.glb",
            "scenetype": "glb",
            "root": "./models/customglb",
            "url": require("./assets/models/custom/Panda.glb"),
            "scale": 100,
            "pos": {
                "x": 1100,
                "y": 1010,
                "z": -2700
            },
            "enable": false
        },
        {
            "name": "Rabbit.glb",
            "scenetype": "glb",
            "root": "./models/customglb",
            "url": require("./assets/models/custom/Rabbit.glb"),
            "scale": 100,
            "pos": {
                "x": 1300,
                "y": 1010,
                "z": 800
            },
            "enable": true
        },
        {
            "name": "Tiger.glb",
            "scenetype": "glb",
            "root": "./models/customglb",
            "url": require("./assets/models/custom/Tiger.glb"),
            "scale": 100,
            "pos": {
                "x": -2000,
                "y": 1010,
                "z": -1000
            },
            "enable": true
        },
        // {
        //     "name": "Parrot.glb",
        //     "scenetype": "glb",
        //     "root": "./models/customglb",
        //     "url": require("./assets/models/customglb/Parrot.glb"),
        //     "scale": 100,
        //     "pos": {
        //         "x": -2000,
        //         "y": 1010,
        //         "z": -2000
        //     },
        //     "enable": false
        // }
    ],
    "movepath": [
        [
            [
                -1266.7,
                5,
                -533
            ],
            [
                -1106.7,
                144,
                -1140
            ],
            [
                -950,
                230,
                -1410
            ],
            [
                -660,
                320,
                -1637
            ],
            [
                -403,
                384,
                -1753
            ],
            [
                120,
                530,
                -1820
            ],
            [
                187,
                530,
                -2100
            ]
        ],
        [
            [
                187,
                530,
                -2000
            ],
            [
                120,
                530,
                -1820
            ],
            [
                337,
                565,
                -1757
            ],
            [
                687,
                658,
                -1617
            ],
            [
                1073,
                779,
                -1237
            ],
            [
                1173,
                859,
                -877
            ],
            [
                1243,
                929,
                -613
            ],
            [
                1147,
                1021,
                -180
            ],
            [
                1085,
                1015,
                4
            ],
            [
                1333,
                1010,
                100
            ]
        ],
        [
            [
                -1000,
                5,
                2000
            ],
            [
                -1267,
                5,
                -533
            ],
            [
                -660,
                340,
                -1637
            ],
            [
                120,
                530,
                -1820
            ],
            [
                1333,
                1010,
                100
            ],
            [
                1800,
                1000,
                -280
            ]
        ]
    ]
}